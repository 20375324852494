import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductsServices from "../../services/ProductsServices";
import CategoriesService from "../../services/CategoriesService";
import Environment from "../../shared/Environment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as cartAction from "../../redux/actions/cartAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Animated } from "react-animated-css";
import "./index.css";

function Products(props) {
  const {
    match: { params },
  } = props;

  const productsApiCall = new ProductsServices();
  const categoryApiCall = new CategoriesService();

  const [allProducts, setAllProducts] = useState([]);
  const [allProductsTemp, setAllProductsTemp] = useState([]);
  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [enteredQuantity, setEnteredQuantity] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState(params.id);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [loadBannerImage, setLoadBannerImage] = useState("");
  const [loadMobileBannerImage, setLoadMobileBannerImage] = useState("");
  const [loadBannerText, setLoadBannerText] = useState("");
  const [loadCategoryText, setLoadCategoryText] = useState("");
  const [showAnimate, setShowAnimate] = useState(false);

  useEffect(() => {
    setShowAnimate(false);
    if (selectedProductId) {
      Promise.all([
        props.loadCartItems(),
        productsApiCall.getRecentProductsByCat(params.id),
        categoryApiCall.getSubcatByCat(params.id),
      ])
        .then(([cartItems, productDetails, subCategories]) => {
          setShowAnimate(true);
          let newProductDetails = productDetails.map((p) => ({
            ...p,
            selectedProductUnit: p.product_pricings[0],
          }));
          setAllProducts(newProductDetails);
          setAllProductsTemp(newProductDetails);
          setAllSubCategories(subCategories);
          setCheckedCategories([]);
          setLoadBannerImage(newProductDetails[0].banner_image_path);
          setLoadBannerText(newProductDetails[0].description);
          setLoadMobileBannerImage(newProductDetails[0].mobile_image_path);
          setLoadCategoryText(newProductDetails[0].category);
          setShowLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [params.id, selectedProductId]);

  const addtoCart = (proDetails) => {
    if (!enteredQuantity || enteredQuantity == "" || enteredQuantity == 0) {
      setEnteredQuantity(1);
      // return false;
    }

    let imageData = JSON.parse(proDetails.product_images);

    let sendItem = {
      brand: proDetails.brand,
      category: proDetails.category,
      // description: proDetails.description,
      id: proDetails.id,
      image_1: imageData[0].name,
      product_name: proDetails.product_name,
      unit_type_id: proDetails.selectedProductUnit.id,
      selling_price: proDetails.selectedProductUnit.selling_price,
      gst: proDetails.selectedProductUnit.gst,
      net_weight: proDetails.selectedProductUnit.net_weight,
      sub_category: proDetails.sub_category,
      unit_type: proDetails.selectedProductUnit.unit_type,
    };

    props
      .addItemtoCart(sendItem, 1)
      .then((result) => {
        setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function updateEnteredQuantity(e) {
    setEnteredQuantity(e);
  }

  const decreaseQuantity = (getCartItem) => {
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) - 1;
    props
      .decreaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const increaseQuantity = (getCartItem) => {
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) + 1;
    props
      .increaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ProductPrice = (props) => {
    return (
      <>
        {props.proDetails.unit_price !== props.proDetails.selling_price ? (
          <span
            key={`pricetag_${props.proDetails.id}`}
            className="letterStrike"
          >
            Rs. {props.proDetails.unit_price}
          </span>
        ) : (
          ""
        )}{" "}
        <span key={`pricetag_${props.proDetails.id}`}>
          Rs. {props.proDetails.selling_price}
        </span>
      </>
    );
  };

  const handleCheckboxCat = (e, s) => {
    const checkedBoxes = [...checkedCategories];
    if (e.target.checked) {
      checkedBoxes.push(s);
    } else {
      const index = checkedBoxes.findIndex((ch) => ch.id === s.id);
      checkedBoxes.splice(index, 1);
    }

    if (checkedBoxes.length > 0) {
      const myArrayFiltered = allProductsTemp.filter((el) => {
        return checkedBoxes.some((f) => {
          return f.sub_category_name == el.sub_category;
        });
      });
      setAllProducts(myArrayFiltered);
    } else {
      setAllProducts(allProductsTemp);
    }

    setCheckedCategories(checkedBoxes);
  };

  return (
    <>
      <div
        className="breadcrumb-area"
        style={{
          backgroundImage: `url(${imagePath}${loadBannerImage})`,
        }}
      >
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <Animated
                animationIn="bounceInLeft"
                animationOut="fadeOut"
                isVisible={showAnimate}
                animationInDuration={2000}
                animationOutDuration={2000}
              >
                <div className="breadcrumb-content">
                  {/* <h4 className="breadcrumb-hrading">{loadBannerText}</h4> */}
                </div>
              </Animated>
            </div>
          </Row>
        </Container>
      </div>
      <div
        className="breadcrumb-area-mobile"
        style={{
          backgroundImage: `url(${imagePath}${loadMobileBannerImage})`,
        }}
      >
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <Animated
                animationIn="bounceInLeft"
                animationOut="fadeOut"
                isVisible={showAnimate}
                animationInDuration={2000}
                animationOutDuration={2000}
              >
                <div className="breadcrumb-content">
                  {/* <h4 className="breadcrumb-hrading">{loadCategoryText}</h4> */}
                </div>
              </Animated>
            </div>
          </Row>
        </Container>
      </div>

      <Container fluid className="inner-page-container-main">
        <Row>
          {showLoader ? (
            <Col cm={12}>
              <h4>Loading</h4>
            </Col>
          ) : (
            <>
              <Col sm={3} className="category-col">
                <Card>
                  <Card.Header>Categories</Card.Header>
                  <Card.Body>
                    <Form className="product-filters">
                      {allSubCategories && allSubCategories.length > 0
                        ? allSubCategories.map((subcat) => {
                          return (
                            <Form.Check
                              custom
                              type="checkbox"
                              id={`custom-checkbox-${subcat.id}`}
                              key={`custom-checkbox-${subcat.id}`}
                              label={subcat.sub_category_name}
                              checked={checkedCategories.find(
                                (ch) => ch.id === subcat.id
                              )}
                              onChange={(e) => handleCheckboxCat(e, subcat)}
                            />
                          );
                        })
                        : ""}
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={9}>
                <Row>
                  {allProducts.length > 0 ? (
                    allProducts.map((pro) => {
                      let productExistsInCart = props.cartitems.some(
                        (item) =>
                          item.id === pro.id &&
                          item.unit_type_id == pro.selectedProductUnit.id
                      );
                      let productInCart = props.cartitems.find(
                        (item) =>
                          item.id === pro.id &&
                          item.unit_type_id == pro.selectedProductUnit.id
                      );
                      let productImages = JSON.parse(pro.product_images);
                      return (
                        <Col
                          md={6}
                          lg={4}
                          xl={3}
                          xs={12}
                          key={`allpro_${pro.id}`}
                        >
                          <Card className="list-product-card list-product-card-main">
                            <Card.Img
                              variant="top"
                              src={`${imagePath}${productImages[0].name}`}
                              style={
                                {
                                  // height: "250px",
                                }
                              }
                            />
                            <Card.Body>
                              <Card.Title
                                as={Link}
                                to={`/product/${pro.product_code}`}
                                className="product-link"
                              >
                                {pro.product_name}
                              </Card.Title>
                              <Card.Text className="inner-link">
                                {pro.category}
                              </Card.Text>
                              <Card.Text className="current-price">
                                <ProductPrice
                                  proDetails={pro.selectedProductUnit}
                                ></ProductPrice>
                              </Card.Text>
                              {/* <div className="view-product-single">
                                <Button
                                  type="button"
                                  className="view-product-btn-single-icon"
                                  as={Link}
                                  to={`/product/${pro.id}`}
                                >
                                  <FontAwesomeIcon icon={faSearch} />
                                </Button>
                              </div> */}
                              <Form.Control
                                size="sm"
                                as="select"
                                name="product_price"
                                className="product_price"
                                defaultValue={
                                  productInCart
                                    ? productInCart.unit_type_id
                                    : pro.product_pricings[0].id
                                }
                                onChange={(e) => {
                                  let selectedPriceObject =
                                    pro.product_pricings.filter((p) => {
                                      return p.id == e.target.value;
                                    });
                                  let priceObject = selectedPriceObject[0];
                                  setAllProducts(
                                    allProducts.map((x) => {
                                      if (x.id == pro.id) {
                                        return {
                                          ...x,
                                          selectedProductUnit: priceObject,
                                        };
                                      } else {
                                        return x;
                                      }
                                    })
                                  );

                                  productExistsInCart = props.cartitems.some(
                                    (item) =>
                                      item.id === pro.id &&
                                      item.unit_type_id == priceObject.id
                                  );
                                  productInCart = props.cartitems.find(
                                    (item) =>
                                      item.id === pro.id &&
                                      item.unit_type_id == priceObject.id
                                  );
                                }}
                              >
                                {pro.product_pricings.map((pri) => {
                                  return (
                                    <option
                                      value={pri.id}
                                      key={`price_option_list_${pri.id}`}
                                    >{`${pri.net_weight} ${pri.unit_type}`}</option>
                                  );
                                })}
                              </Form.Control>
                              {pro.sold_out == "Yes" ? (
                                <Button
                                  type="button"
                                  className="out-of-cart-list-btn-list btn-block"
                                >
                                  Out of stock
                                </Button>
                              ) : (
                                <>
                                  {productExistsInCart &&
                                    productInCart.unit_type_id ==
                                    pro.selectedProductUnit.id ? (
                                    <InputGroup className="cart-change-list-product">
                                      <InputGroup.Prepend>
                                        <InputGroup.Text
                                          onClick={() =>
                                            decreaseQuantity(productInCart)
                                          }
                                        >
                                          -
                                        </InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <FormControl
                                        disabled
                                        id="inlineFormInputGroup"
                                        placeholder=""
                                        value={productInCart.quantity}
                                        className="cart-change-list-product-input"
                                      />
                                      <InputGroup.Prepend>
                                        <InputGroup.Text
                                          onClick={() =>
                                            increaseQuantity(productInCart)
                                          }
                                        >
                                          +
                                        </InputGroup.Text>
                                      </InputGroup.Prepend>
                                    </InputGroup>
                                  ) : (
                                    <div className="add-to-cart-container">
                                      {/* <FormControl
                                        id={`inlineFormInputGroup_prolist_${pro.id}`}
                                        placeholder="Quantity"
                                        className="mb-2 custom-list-individual-product custom-quantity-list-product"
                                        type="text"
                                        name="productQuantity"
                                        maxLength={2}
                                        onChange={updateEnteredQuantity}
                                      /> */}
                                      <Button
                                        type="button"
                                        className="add-to-cart-list-btn  btn-block add-to-cart-list-btn-list"
                                        onClick={() => addtoCart(pro)}
                                      >
                                        Add
                                      </Button>
                                    </div>
                                  )}
                                </>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })
                  ) : (
                    <span className="no-products">
                      Sorry.. No products available
                    </span>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
}

Products.propTypes = {
  cartitems: PropTypes.array.isRequired,
  addItemtoCart: PropTypes.func.isRequired,
  increaseCartItemQuantity: PropTypes.func.isRequired,
  decreaseCartItemQuantity: PropTypes.func.isRequired,
  loadCartItems: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    cartitems: state.cartitems,
  };
}

const mapDispatchToProps = {
  addItemtoCart: cartAction.addItemtoCart,
  increaseCartItemQuantity: cartAction.increaseCartItemQuantity,
  decreaseCartItemQuantity: cartAction.decreaseCartItemQuantity,
  loadCartItems: cartAction.loadCartItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
