import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Environment from "../../shared/Environment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as cartAction from "../../redux/actions/cartAction";
import "./index.css";
import { Helmet } from "react-helmet";

function Cart(props) {
  const [imagePath, setImagePath] = useState(Environment.image_url);

  useEffect(() => {
    props
      .loadCartItems()
      .then((result) => { })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const decreaseQuantity = (getCartItem) => {
    console.log(getCartItem);
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) - 1;
    props
      .decreaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        // setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const increaseQuantity = (getCartItem) => {
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) + 1;
    props
      .increaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        // setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function removeFromCart(getItem) {
    props
      .removeItemFromCart(getItem)
      .then((result) => { })
      .catch((err) => {
        console.log(err);
      });
  }

  function TotalAmount() {
    let totalAmount = 0;
    props.cartitems.map((car) => {
      totalAmount =
        totalAmount +
        parseFloat(car.selling_price).toFixed(2) * parseInt(car.quantity);
    });

    let formatedAmount = parseFloat(totalAmount).toFixed(2);
    return formatedAmount;
  }

  function GSTAmount() {
    let gstAmount = 0;
    props.cartitems.map((car) => {
      gstAmount =
        gstAmount + parseFloat(car.gst).toFixed(2) * parseInt(car.quantity);
    });

    let formatedAmount = parseFloat(gstAmount).toFixed(2);
    return formatedAmount;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cart - Five Element "Food from Heaven"</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/cart" />
        <meta
          name="keywords"
          content="Cart - Five Element 'Food from Heaven'"
        />

        <meta
          name="description"
          content="Cart - Five Element 'Food from Heaven'"
        />
      </Helmet>
      <Container fluid className="inner-page-container-main">
        <Row>
          <Col sm={12} className="mobile-order-details">
            {props.cartitems && props.cartitems.length > 0
              ? props.cartitems.map((item, index) => {
                return (
                  <>
                    <div className="product-details-order">
                      <div className="img-container">
                        <img
                          src={`${imagePath}${item.image_1}`}
                          className="product-image-order-page"
                        ></img>
                        <span onClick={() => removeFromCart(item)}>
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                      </div>
                      <div>
                        <Link to={`/product/${item.id}`}>
                          <small>{item.product_name}</small>
                        </Link>
                        <br></br>
                        <small>Rs. {item.selling_price}</small>
                        <br></br>
                        <small>
                          {item.net_weight} {item.unit_type} x {item.quantity}{" "}
                          = Rs. {parseInt(item.quantity) * item.selling_price}
                        </small>
                      </div>
                    </div>
                  </>
                );
              })
              : ""}
          </Col>
          <Col sm={12} className="desktop-order-details">
            <Table
              striped
              bordered
              hover
              size="sm"
              responsive
              className="cart-table cart-table-content"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>Unit Price</th>
                  <th>Quantity</th>
                  <th>Sub Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {props.cartitems && props.cartitems.length > 0 ? (
                  <>
                    {props.cartitems.map((item, index) => {
                      return (
                        <tr key={`cartpage_pro_${item.id}`}>
                          <td>{index + 1}</td>
                          <td>
                            {item.product_name}
                            <br></br>
                            {item.net_weight} {item.unit_type}
                          </td>
                          <td>Rs. {item.selling_price}</td>
                          <td>
                            <div className="cartItem plus-minus-input">
                              <div className="input-group-button">
                                <button
                                  type="button"
                                  className="button hollow circle"
                                  onClick={() => decreaseQuantity(item)}
                                >
                                  <FontAwesomeIcon icon={faMinus} />
                                </button>
                              </div>
                              <FormControl
                                id="inlineFormInputGroup"
                                placeholder=""
                                value={item.quantity}
                                className="cart-change-list-product-input"
                                style={{ width: "15%" }}
                              />
                              <div className="input-group-button">
                                <button
                                  type="button"
                                  className="button hollow circle"
                                  onClick={() => increaseQuantity(item)}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>
                            Rs. {parseInt(item.quantity) * item.selling_price}
                          </td>
                          <td>
                            <Button
                              type="button"
                              className="remove-item-from-cart"
                              onClick={() => removeFromCart(item)}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colspan="5" className="cart-total-td">
                        Total <br />
                        {/* <p style={{ fontSize: "14px", color: "brown" }}>
                          Tax amount Rs. <GSTAmount></GSTAmount> included in the
                          total amount.
                        </p> */}
                      </td>
                      <td colspan="1" className="cart-total-td-price">
                        Rs. <TotalAmount></TotalAmount>
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan={6} className="empty-cart">
                      Cart is empty
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        {props.cartitems && props.cartitems.length > 0 ? (
          <Row>
            <Col className="checkout-btn-container">
              <Button
                className="checkout-btn"
                as={Link}
                to="/checkout"
                style={{ background: "#4FB68B !important" }}
              >
                Proceed to Checkout
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Container>
    </>
  );
}

Cart.propTypes = {
  cartitems: PropTypes.array.isRequired,
  addItemtoCart: PropTypes.func.isRequired,
  increaseCartItemQuantity: PropTypes.func.isRequired,
  decreaseCartItemQuantity: PropTypes.func.isRequired,
  loadCartItems: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    cartitems: state.cartitems,
  };
}

const mapDispatchToProps = {
  addItemtoCart: cartAction.addItemtoCart,
  increaseCartItemQuantity: cartAction.increaseCartItemQuantity,
  decreaseCartItemQuantity: cartAction.decreaseCartItemQuantity,
  loadCartItems: cartAction.loadCartItems,
  removeItemFromCart: cartAction.removeItemFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
