import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import "./staticArea.css";

function StaticArea(props) {
  return (
    <div style={{ marginLeft: '80px' }}>
      <div className="static-area-wrap">
        <Row>
          {/* <Col sm={4}>
            <div className="single-static">
              <img
                src={"assets/static-icons-1.png"}
                alt=""
                className="img-responsive"
              />
              <div className="single-static-meta">
                <h4>Free Shipping</h4>
                <p>All orders above ₹2000 qualify for free shipping.</p>
              </div>
            </div>
          </Col> */}
          <Col sm={6}>
            <div className="single-static">
              <img
                src={"assets/eco.png"}
                alt=""
                className="img-responsive"
              />
              <div className="single-static-meta">
                <h4>Climate-friendly</h4>
                <p>We aim to achieve climate neutrality by 2030.</p>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div className="single-static">
              <img
                src={"assets/return.png"}
                alt=""
                className="img-responsive"
              />
              <div className="single-static-meta">
                <h4>100 % complete replacement</h4>
                <p>
                  If an issue arises after the purchase, there is a full replacement policy in place.
                </p>
              </div>
            </div>
          </Col>
          <br />
        </Row>
      </div>
    </div>
  );
}

export default StaticArea;
