import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faMapMarker,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  fab,
  faTwitterSquare,
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import aboutImg from "../../assets/images/about.png";
import LokeshImg from "../../assets/images/lokesh.png";
import SridarImg from "../../assets/images/sridar.png";


import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import Environment from "../../shared/Environment";
import AuthService from "../../services/AuthService";
import ToastCom from "../common/toasts";
import "./index.css";
import { Helmet } from "react-helmet";

function About(props) {
  const authApiCall = new AuthService();
  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState();

  function handleFormInput(e) {
    const { name, value } = e.target;
    setLoginData((loginData) => ({
      ...loginData,
      [name]: value,
    }));
  }

  function userLogin(e) {
    e.preventDefault();
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Story - Five Element "Food from Heaven"</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/our-story" />
        <meta
          name="keywords"
          content="Our Story - Five Element 'Food from Heaven'"
        />

        <meta
          name="description"
          content="Our Story - Five Element 'Food from Heaven'"
        />
      </Helmet>
      <div className="breadcrumb-area-about">
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <div className="breadcrumb-content">
                <h1 className="breadcrumb-heading">Our Story</h1>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Container className="about-area">
        <h5 className="header-page">About the company</h5>
        <div className="text-center">
          <img
            src={"assets/border-line.png"}
            className="bottom-border-line"
          />
        </div>
        <Row>

          <Col sm={6}>
            <div className="container">

              <Row className="numbers">
                <Col>
                  <div className="item mb-50">
                    <h3><span className="nbr custom-font">253</span></h3>
                    <h6>Products</h6>
                  </div>
                </Col>
                <Col>
                  <div className="item mb-50">
                    <h3><span className="nbr custom-font">425+</span></h3>
                    <h6>Farmers</h6>
                  </div>
                </Col>
              </Row>
              <Row className="numbers">
                <Col>
                  <div className="item mb-50">
                    <h3><span className="nbr custom-font">247</span></h3>
                    <h6>Operating Pincodes</h6>
                  </div>
                </Col>
                <Col>
                  <div className="item mb-50">
                    <h3><span className="nbr custom-font">1755+</span></h3>
                    <h6>Acre</h6>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={6}>
            <div className="container">
              <div class="txt-cont1">
                <p class="mb-20">We have developed this company has a cloud grocery shop such that the price in-between like store maintenance, dealer margin & etc. are completely removed and brought to one. The processing and packaging unit is done in high level of hygienic condition using advance technology by following the proper guidelines and norms. The testimony to our processing, packing and quality of our product is our accreditation from “Ecocert France” for National Programme of Organic Production (NPOP), National Organic Program (NOP) and European Union (EU). These certifications are awarded based on the results of rigorous test, inspections and audits. </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="about-area">
          <Col sm={6}>
            <div className="txt-cont">
              <p className="mb-20">In order to protect the environment and a step towards our mission we have initiated a moment called “back to roots”. The main objective of this movement is to support farmers who have a passion for cultivating traditional crops through age old organic methods. The farmers who have been shortlisted in this movement are having agriculture land between 4-10 acres. These farmers have been offered a contract such that whatever they cultivate will be procured completely by us, in which 400+ farmers throughout India have been volunteered as of now. This contract-based procurement is done to support and protect the farmers and also to ensure the farmer that whatever they cultivate in their agricultural land will be sold completely.</p>
            </div>
          </Col>
          <Col sm={6}>
            <img
              className="d-block w-100"
              src={aboutImg}
            />
            {/* style={{ height: '265px' }} */}
          </Col>
        </Row>
        <Row className="about-area">
          <Col sm={12}>
            <h5 className="header-page">Our Story</h5>
            <div className="text-center">
              <img
                src={"assets/border-line.png"}
                className="bottom-border-line"
              />
            </div>
          </Col>
          <Col sm={6}>
            <img
              className="d-block w-100 "
              src={SridarImg}
              style={{ height: '445px', marginTop: '24px' }}
            />
          </Col>
          <Col sm={6}>
            <div className="txt-cont mt-10" >
              <p className="mb-20">Our parent company S2 organic Foods India was started in the year 2021 by Mr. Sridhar C IPS (retd), from a traditional agricultural background family from Tamil Nadu's delta region, his contribution towards agriculture started during his childhood days itself when he used to spend his free time taking care of his own farmland and cattle animals. He completed his B.Sc in Chemistry in Thanjavur as a chemistry graduate he understood the influence of chemicals used in agriculture and the side effect towards the environment and soil, though chemical fertilizers increase growth and vigor, but which would accumulate toxic chemicals in the food we eat. That’s where his interest towards Organic farming started. He choose civil service as his career and joined IPS and served in Tamil Nadu.</p>
              <p className="mb-20">
                After his retirement, he has started the company with a mission to support local organic farmers and also to guide them through advanced technology used in agricultural activities. The “Back to roots” movement was started by him and as of now 400+ farmers are enrolled in it and 230 acres of farmland have been converted to organic farms from the conventional farm in one year.</p>
            </div>
          </Col>
          <Col sm={6}>
            <div className="txt-cont mt-10" >
              <p className="mb-20">Logesh B.E graduate of manufacturing engineering from CEG, Anna University, His involvement towards organic sparked in the year 2018, during the period where protest and suicides of farmers were going on, he identified the main root cause for the problems faced by the farmers, is most of the farmers borrow money to buy chemical fertilizers, pesticides, and seeds. They borrow that money even with high interest and they sell their product at the base price or even below to the middlemen. </p>
              <p className="mb-20">
                If we switch to organic, we can eliminate chemical fertilizers and pesticides and we can also prevent our land from being unfertile. He joined hands with the company and helped the company thrive technologically..</p>
            </div>
          </Col>
          <Col sm={6}>
            <img
              className="d-block w-100 "
              src={LokeshImg}
              style={{ height: '445px', marginTop: '25px' }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default About;
