import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import "./certificates.css";

function Certificate(props) {
  return (
    <Container >

      <Row className="mt-70">
        <div className="col-md-12 mb-20">
          <h5 className="header-certification">Our Organic Certifications</h5>
          <div className="text-center">
            <img
              src={"assets/border-line.png"}
              className="bottom-border-line"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item certificate-item text-center">
            <div className="skill" data-value="1">
              <span>
                <img src={"assets/certification/ICON-03.png"} className="w-50" />
              </span>
              <h6>USDA/NOP</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item certificate-item text-center">
            <div className="skill" data-value="1">
              <span>
                <img src={"assets/certification/ICON-02.png"} className="w-50" />
              </span>
              <h6>EOS/EU</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item certificate-item text-center">
            <div className="skill" data-value="1">
              <span>
                <img src={"assets/certification/ICON-04.png"} className="w-50" />
              </span>
              <h6>NPOP</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item certificate-item text-center">
            <div className="skill" data-value="1">
              <span>
                <img src={"assets/certification/ICON-05.png"} className="w-50" />
              </span>
              <h6>JAVIK BHARATH</h6>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default Certificate;
