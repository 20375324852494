import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import AuthService from "../../../services/AuthService";
import "./index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword(props) {
  const authApiCall = new AuthService();
  const [verifyUserData, setverifyUserData] = useState({
    mobile_number: "",
  });
  const [showResetForm, setShowResetForm] = useState(false);
  const [resetPasswordForm, setResetPasswordForm] = useState({
    mobile_number: "",
    password_otp: "",
    new_password: "",
    confirm_password: "",
  });
  const [buttonLoader, setButtonLoader] = useState(false);

  function handleFormInput(e) {
    const { name, value } = e.target;
    setverifyUserData((verifyUserData) => ({
      ...verifyUserData,
      [name]: value,
    }));
  }

  function handleFormInputReset(e) {
    const { name, value } = e.target;
    setResetPasswordForm((resetPasswordForm) => ({
      ...resetPasswordForm,
      [name]: value,
    }));
  }

  function userVerify(e) {
    e.preventDefault();
    setButtonLoader(true);
    authApiCall
      .verifyUserOTPEmail(verifyUserData)
      .then((data) => {
        toast.success("OTP sent successfully to your mobile number", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          setButtonLoader(false);
          setShowResetForm(true);
        }, 5000);
      })
      .catch((err) => {
        setButtonLoader(false);
        toast.error(err.data.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  function userResetPassword(e) {
    e.preventDefault();
    setButtonLoader(true);
    let sendData = resetPasswordForm;
    sendData.mobile_number = verifyUserData.mobile_number;
    authApiCall
      .resetUserPassword(sendData)
      .then((data) => {
        toast.success("Password Changed Successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          props.history.push("/user/login");
        }, 5000);
      })
      .catch((err) => {
        setButtonLoader(false);
        toast.error(err.data.error[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  return (
    <>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center">
          <Col sm={6}>
            <Card className="login-content">
              <Card.Header className="login-header">
                Forgot Password
              </Card.Header>
              <Card.Body>
                {showResetForm ? (
                  <Form onSubmit={userResetPassword}>
                    <Form.Group controlId="formBasicName">
                      <Form.Control
                        type="text"
                        placeholder="Enter OTP"
                        required
                        name="password_otp"
                        value={resetPasswordForm.password_otp}
                        onChange={handleFormInputReset}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        required
                        name="new_password"
                        value={resetPasswordForm.new_password}
                        onChange={handleFormInputReset}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicConfirmPassword">
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        required
                        name="confirm_password"
                        value={resetPasswordForm.confirm_password}
                        onChange={handleFormInputReset}
                      />
                    </Form.Group>
                    <div className="button-box">
                      <div className="login-toggle-btn">
                        <Button
                          className={`global-submit-btn ${
                            buttonLoader ? "loading-btn" : ""
                          }`}
                          type="submit"
                        >
                          {buttonLoader ? "Processing..." : "Submit"}
                        </Button>
                        <Link to="/user/login" className="login-link">
                          Back to login?
                        </Link>
                      </div>
                    </div>
                  </Form>
                ) : (
                  <Form onSubmit={userVerify}>
                    <Form.Group controlId="formBasicName">
                      <Form.Control
                        type="text"
                        placeholder="Enter Mobile Number"
                        required
                        name="mobile_number"
                        value={verifyUserData.mobile_number}
                        onChange={handleFormInput}
                      />
                    </Form.Group>
                    <div className="button-box">
                      <div className="login-toggle-btn ">
                        <Button
                          className={`global-submit-btn btn-sm ${
                            buttonLoader ? "loading-btn" : ""
                          }`}
                          type="submit"
                        >
                          {buttonLoader ? "Processing..." : "Submit"}
                        </Button>
                        <Link to="/user/login" className="login-link">
                          Back to login?
                        </Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default ForgotPassword;
