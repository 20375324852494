import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthService from "../../../services/AuthService";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

function Register(props) {
  const authApiCall = new AuthService();
  const [registerData, setRegisterData] = useState({
    name: "",
    email: "",
    mobile_number: "",
    password: "",
  });
  const [buttonLoader, setButtonLoader] = useState(false);
  function handleFormInput(e) {
    const { name, value } = e.target;
    setRegisterData((registerData) => ({
      ...registerData,
      [name]: value,
    }));
  }

  function userRegister(e) {
    e.preventDefault();
    setButtonLoader(true);
    authApiCall
      .registerUser(registerData)
      .then((data) => {
        toast.success(
          "Please enter the otp that is sent to your mobile number",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          props.history.push(`/user/verify/${data.user}`);
        }, 5000);
      })
      .catch((err) => {
        setButtonLoader(false);
        toast.error(err.data.error[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register - Five Element "Food from Heaven"</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/user/register" />
        <meta
          name="keywords"
          content="Register - Five Element 'Food from Heaven'"
        />
        <meta
          name="description"
          content="Register - Five Element 'Food from Heaven'"
        />
      </Helmet>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center">
          <Col sm={6}>
            <Card className="login-content">
              <Card.Header className="login-header">Register</Card.Header>
              <Card.Body>
                <Form onSubmit={userRegister}>
                  <Form.Group controlId="formBasicName">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      required
                      name="name"
                      value={registerData.name}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      required
                      name="email"
                      value={registerData.email}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicMobile">
                    <Form.Control
                      type="tel"
                      placeholder="Mobile Number"
                      required
                      name="mobile_number"
                      value={registerData.mobile_number}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      required
                      name="password"
                      value={registerData.password}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <Button
                    className={`global-submit-btn ${buttonLoader ? "loading-btn" : ""
                      }`}
                    type="submit"
                  >
                    {buttonLoader ? "Processing..." : "Submit"}
                  </Button>
                  <div className="not-register-link">
                    <p>
                      Already registered ?{" "}
                      <Link to="/user/login">Click here</Link> to login
                    </p>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default Register;
