import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import PaumentSuccess from "./tickgreen.png";
import "./paymentSuccess.css";

function PaymentSuccess(props) {
  const {
    match: { params },
  } = props;
  return (
    <>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center">
          <Col sm={6} className="order-details">
            <h4>Success!</h4>
            <h5>THANK YOUR FOR YOUR PAYMENT</h5>
            <h6>Order number : #00000{params.id}</h6>
            <img src={PaumentSuccess} alt="Payment success"></img>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PaymentSuccess;
