import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import AuthService from "../../../services/AuthService";
import AddressService from "../../../services/AddressService";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import * as cartAction from "../../../redux/actions/cartAction";
import * as addressAction from "../../../redux/actions/addressAction";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ManageAddress(props) {
  const authApiCall = new AuthService();
  const addressApiCall = new AddressService();

  const [showNewAddressModal, setShowNewAddressModal] = useState(false);
  const [newAddressForm, setNewAddressForm] = useState({
    id: "",
    name: "",
    mobile: "",
    street: "",
    city: "",
    state: "",
    landmark: "",
    pincode: "",
    user_id: "",
  });
  const [userAddresses, setUserAddresses] = useState([]);
  const [deliveryPincodes, setDeliveryPincodes] = useState([]);
  const [pincodeChange, setPincodeChange] = useState();
  const [showPincodeError, setShowPincodeError] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    if (!authApiCall.loggedIn()) {
      props.history.push("/user/login");
    } else {
      props
        .loadUserAddresses()
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });

      Promise.all([addressApiCall.getDeliveryPincodes()])
        .then(([pincodes]) => {
          setDeliveryPincodes(pincodes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleCloseAddressModal = () => {
    setNewAddressForm({
      id: "",
      name: "",
      mobile: "",
      street: "",
      city: "",
      state: "",
      landmark: "",
      pincode: "",
      user_id: "",
    });
    setPincodeChange([]);
    setShowNewAddressModal(false);
    setIsEditAddress(false);
  };
  const handleShowAddressModal = () => {
    setShowNewAddressModal(true);
  };

  function handleNewAddressForm(e) {
    const { name, value } = e.target;
    setNewAddressForm((newAddressForm) => ({
      ...newAddressForm,
      [name]: value,
    }));
  }

  function saveNewAddress(e) {
    e.preventDefault();
    setButtonLoader(true);
    if (!pincodeChange) {
      setShowPincodeError(true);
      return false;
    }
    let userDetails = JSON.parse(localStorage.getItem("loggedinUserDetails"));
    let userId = userDetails.id;
    var sendData = newAddressForm;
    sendData.user_id = userId.toString();
    sendData.pincode = pincodeChange[0].pincode;

    if (isEditAddress) {
      props
        .updateUserAddress(sendData)
        .then((result) => {
          setButtonLoader(false);
          setNewAddressForm({
            id: "",
            name: "",
            mobile: "",
            street: "",
            city: "",
            state: "",
            landmark: "",
            pincode: "",
            user_id: "",
          });
          setPincodeChange([]);
          setShowNewAddressModal(false);
          toast.success("Address updated successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          setButtonLoader(false);
          toast.error(err.data.error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      props
        .addNewAddress(sendData)
        .then((result) => {
          setButtonLoader(false);
          setNewAddressForm({
            id: "",
            name: "",
            mobile: "",
            street: "",
            city: "",
            state: "",
            landmark: "",
            pincode: "",
            user_id: "",
          });
          setPincodeChange([]);
          setShowNewAddressModal(false);
          toast.success("New address is added", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          setButtonLoader(false);
          toast.error(err.data.error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  const onChangePincode = (selected) => {
    setPincodeChange(selected);
  };

  function deleteAddress(getId) {
    alert("coming soon");
  }

  function editAddress(getId) {
    let selectedAddress = props.userAddresses.filter(
      (address) => address.id == getId
    );
    setNewAddressForm(selectedAddress[0]);
    let selectedPincode = selectedAddress[0].pincode;
    let setPincodeSelected = deliveryPincodes.filter(
      (pin) => pin.pincode == selectedPincode
    );
    setPincodeChange(setPincodeSelected);
    let thisPincodeName = "pincode";
    setNewAddressForm((newAddressForm) => ({
      ...newAddressForm,
      [thisPincodeName]: setPincodeSelected[0].pincode,
    }));
    setIsEditAddress(true);
    setShowNewAddressModal(true);
  }

  return (
    <>
      <Container className="inner-page-container-main">
        <Row className="checkout-page">
          <Col sm={12}>
            <Card>
              <Card.Header>
                Manage Address{" "}
                <Button
                  type="button"
                  className="btn-primary  btn-sm float-right"
                  onClick={handleShowAddressModal}
                >
                  Add New
                </Button>
              </Card.Header>
              <Card.Body>
                <Row className="address-cards">
                  {props.userAddresses && props.userAddresses.length > 0 ? (
                    props.userAddresses.map((address, index) => {
                      return (
                        <Col sm={4} key={`address_${address.id}`}>
                          <Card className="ma-card">
                            <Card.Header>
                              Address {index + 1}{" "}
                              <Button
                                type="button"
                                className="edit-address-btn"
                                onClick={() => editAddress(address.id)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                              {/* <Button
                                type="button"
                                className="delete-address-btn"
                                onClick={() => deleteAddress(address.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button> */}
                            </Card.Header>
                            <Card.Body>
                              <div className="address-card">
                                <p>
                                  {address.name}, {address.mobile}
                                </p>
                                <p>{address.street}</p>
                                <p>
                                  {address.landmark}, {address.state}
                                </p>
                                <p>
                                  {address.city}, {address.pincode}
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })
                  ) : (
                    <Button
                      type="button"
                      className="add-new-address-btn"
                      onClick={handleShowAddressModal}
                    >
                      Add New
                    </Button>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal
          className="global-modal"
          show={showNewAddressModal}
          onHide={handleCloseAddressModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Address</Modal.Title>
          </Modal.Header>
          <Form onSubmit={saveNewAddress}>
            <Modal.Body>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    value={newAddressForm.name}
                    onChange={handleNewAddressForm}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Mobileno</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Mobileno"
                    name="mobile"
                    value={newAddressForm.mobile}
                    onChange={handleNewAddressForm}
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="formGridAddress1">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Full address"
                  name="street"
                  value={newAddressForm.street}
                  onChange={handleNewAddressForm}
                  required
                />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridLandmark">
                  <Form.Label>Landmark</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Landmark"
                    name="landmark"
                    value={newAddressForm.landmark}
                    onChange={handleNewAddressForm}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    name="state"
                    value={newAddressForm.state}
                    onChange={handleNewAddressForm}
                    required
                  >
                    <option value="">---Select---</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    name="city"
                    value={newAddressForm.city}
                    onChange={handleNewAddressForm}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>Pincode</Form.Label>
                  {/* <Form.Control
                    type="text"
                    placeholder="Pincode"
                    name="pincode"
                    value={newAddressForm.pincode}
                    onChange={handleNewAddressForm}
                    required
                  /> */}
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="pincode"
                    onChange={onChangePincode}
                    options={deliveryPincodes}
                    placeholder="Choose a pincode..."
                    selected={pincodeChange}
                    required
                    name="pincode"
                  />
                  {showPincodeError ? (
                    <Form.Text id="pincodeHelpBlock" muted>
                      Please select a pincode from the list
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={handleCloseAddressModal}
              >
                Close
              </Button>
              <Button
                type="submit"
                className={`btn-five ${buttonLoader ? "loading-btn" : ""}`}
              >
                {buttonLoader ? "Processing..." : "Submit"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <ToastContainer />
      </Container>
    </>
  );
}

ManageAddress.propTypes = {
  userAddresses: PropTypes.array.isRequired,
  loadUserAddresses: PropTypes.func.isRequired,
  addNewAddress: PropTypes.func.isRequired,
  deleteUserAddresses: PropTypes.func.isRequired,
  getAddressById: PropTypes.func.isRequired,
  updateUserAddress: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userAddresses: state.userAddresses,
  };
}

const mapDispatchToProps = {
  loadUserAddresses: addressAction.loadUserAddresses,
  addNewAddress: addressAction.addNewAddress,
  deleteUserAddresses: addressAction.deleteUserAddresses,
  getAddressById: addressAction.getAddressById,
  updateUserAddress: addressAction.updateUserAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAddress);
