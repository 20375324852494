import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import AuthService from "../../../services/AuthService";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as loginAction from "../../../redux/actions/loginAction";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChangePassword(props) {
  const authApiCall = new AuthService();
  const [changePasswordData, setChangePasswordData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    if (!authApiCall.loggedIn()) {
      props.history.push("/user/login");
    }
  }, []);

  function handleFormInput(e) {
    const { name, value } = e.target;
    setChangePasswordData((changePasswordData) => ({
      ...changePasswordData,
      [name]: value,
    }));
  }

  function userLogin(e) {
    e.preventDefault();
    setButtonLoader(true);
    authApiCall
      .changePassword(changePasswordData)
      .then((data) => {
        toast.success(
          "Your password has been changed. Please login to continue.",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          props
            .logoutCurrentUser()
            .then((result) => {
              props.history.push("/user/login");
            })
            .catch((err) => {
              console.log(err);
            });
        }, 5000);
      })
      .catch((err) => {
        setButtonLoader(false);
        toast.error(err.data.error[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  return (
    <>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center">
          <Col sm={6}>
            <Card>
              <Card.Header>Change Password</Card.Header>
              <Card.Body>
                <Form onSubmit={userLogin}>
                  <Form.Group controlId="formBasicOldPassword">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Old Password"
                      required
                      name="old_password"
                      value={changePasswordData.old_password}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicNewPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="New Password"
                      required
                      name="new_password"
                      value={changePasswordData.new_password}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      required
                      name="confirm_password"
                      value={changePasswordData.confirm_password}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <Button
                    className={`global-submit-btn ${
                      buttonLoader ? "loading-btn" : ""
                    }`}
                    type="submit"
                  >
                    {buttonLoader ? "Processing..." : "Submit"}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

ChangePassword.propTypes = {
  logoutCurrentUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    loggedinUser: state.loggedinUser,
  };
}

const mapDispatchToProps = {
  logoutCurrentUser: loginAction.logoutCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
