import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faMapMarker,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  fab,
  faTwitterSquare,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import Environment from "../../shared/Environment";
import AuthService from "../../services/AuthService";
import ToastCom from "../common/toasts";
import "./index.css";
import { Helmet } from "react-helmet";

function ContactUs(props) {
  const authApiCall = new AuthService();
  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    mobile: "",
    content: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);

  function handleFormInput(e) {
    const { name, value } = e.target;
    setContactForm((contactForm) => ({
      ...contactForm,
      [name]: value,
    }));
  }

  function submitFeedback(e) {
    e.preventDefault();
    setButtonLoader(true);
    authApiCall
      .submitEnquiry(contactForm)
      .then((data) => {
        setTimeout(() => {
          setShowToast(true);
          setToastType("success");
          setToastMessage(["Your feedback is submitted"]);
          setButtonLoader(false);
          setContactForm({
            name: "",
            email: "",
            mobile: "",
            content: "",
          });
        }, 5000);
      })
      .catch((err) => {
        setButtonLoader(false);
        setShowToast(true);
        setToastType("error");
        setToastMessage(err.data.error);
      });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - Five Element "Food from Heaven"</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/contact-us" />
        <meta
          name="keywords"
          content="Contact Us - Five Element 'Food from Heaven'"
        />

        <meta
          name="description"
          content="Contact Us - Five Element 'Food from Heaven'"
        />
      </Helmet>
      <div className="breadcrumb-area-contact">
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <div className="breadcrumb-content">
                <h1 className="breadcrumb-hrading">Contact us</h1>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center">
          <Col sm={6}>
            <div className="contact-info-wrap">
              <div className="single-contact-info">
                <div className="contact-icon">
                  <i>
                    <FontAwesomeIcon icon={faPhone} />
                  </i>
                </div>
                <div className="contact-info-dec">
                  <p>044-42866033 </p>
                  <p>+91-9789066033</p>
                </div>
              </div>
              <div className="single-contact-info">
                <div className="contact-icon">
                  <i>
                    <FontAwesomeIcon icon={faGlobe} />
                  </i>
                </div>
                <div className="contact-info-dec">
                  <p>
                    <a href="#">support@fiveelementorganic.com</a>
                  </p>
                  <p>
                    <a href="#">fiveelementorganic.com</a>
                  </p>
                </div>
              </div>
              <div className="single-contact-info">
                <div className="contact-icon">
                  <i>
                    <FontAwesomeIcon icon={faMapMarker} />
                  </i>
                </div>
                <div className="contact-info-dec">
                  <p>Office Address</p>
                  <p>74, Sundram Street, </p>
                  <p>Chinmaya Nagar Stage 1,</p>
                  <p>Koyambedu,</p>
                  <p> Chennai, Tamil Nadu, 600092.</p>
                </div>
              </div>
              <div className="contact-social">
                <h3>Follow Us</h3>
                <div className="social-info">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/Fiveelementfoodfromheaven"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/fiveelementfoodfromheaven"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/fiveelement_ffh"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faTwitterSquare} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UC6fNAvX4DzDngvSfKGQY9Jg"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div className="contact-form">
              <div className="contact-title mb-30">
                <h2>Get In Touch</h2>
              </div>
              <form
                className="contact-form-style"
                id="contact-form"
                onSubmit={submitFeedback}
              >
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <input
                      name="name"
                      value={contactForm.name}
                      onChange={handleFormInput}
                      placeholder="Name*"
                      type="text"
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <input
                      name="email"
                      value={contactForm.email}
                      onChange={handleFormInput}
                      placeholder="Email*"
                      type="email"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <input
                      name="mobile"
                      value={contactForm.mobile}
                      onChange={handleFormInput}
                      placeholder="Mobile*"
                      type="text"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-sm-12 ">
                    <textarea
                      name="content"
                      value={contactForm.content}
                      onChange={handleFormInput}
                      placeholder="Your Message*"
                      rows="3"
                      required
                    ></textarea>
                    <button
                      className={`global-submit-btn ${buttonLoader ? "loading-btn" : ""
                        }`}
                      type="submit"
                    >
                      {buttonLoader ? "Processing..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
        <ToastCom
          showToast={showToast}
          toastType={toastType}
          toastMessage={toastMessage}
          setShowToast={setShowToast}
        ></ToastCom>
      </Container>
    </>
  );
}

export default ContactUs;
