import React, { useState, useEffect } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./comboList.css";
import ProductsServices from "../../services/ProductsServices";
import Environment from "../../shared/Environment";

function ComboList(props) {
  const categoryApiCall = new ProductsServices();
  const [allCategories, setAllCategories] = useState([]);
  const [allCombos, setAllCombos] = useState([]);
  const [imagePath, setImagePath] = useState(Environment.image_url);

  useEffect(() => {
    categoryApiCall
      .getComboProducts()
      .then((res) => {
        setAllCategories(res);
        setAllCombos(res);
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container className="featured-categories-container">
      <div className="section-title">
        <h2>Product Combos</h2>
        <div className="text-center">
          <img
            src={"assets/border-line.png"}
            className="bottom-border-line"
          />
        </div>
      </div>
      <Row>
        {allCombos.length > 0
          ? allCombos.map((pro) => {
            return (
              <Col
                md={4}
                lg={4}
                xl={4}
                xs={12}
                className="categories-card"
                key={`cat_${pro.id}`}
              >
                {pro.product_images.length > 0 ?
                  <Card className="bg-dark text-white">
                    <Card.Img
                      src={`${imagePath}${pro.product_images[0]["name"]}`}
                      alt="Card image"
                    />
                    <Card.ImgOverlay className="image-overlay d-flex flex-column justify-content-center align-items-right">
                      <div style={{ background: "#fdfae0b3", padding: '5px', textAlign: 'right' }}>
                        <Card.Title className="text-right">
                          {pro.product_name}
                        </Card.Title>
                        <Card.Title className="card-sub-title text-right">
                          {pro.product_count} Products
                        </Card.Title>
                        <Link
                          style={{ textAlign: 'center !important' }}
                          to={`/product/${pro.product_code}`}
                          className="view-cat-product-btn"
                        >
                          Shop Now
                        </Link>
                      </div>
                    </Card.ImgOverlay>
                  </Card>
                  : ""}
              </Col>
            );
          })
          : ""}
      </Row>
    </Container>
  );
}

export default ComboList;
