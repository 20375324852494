import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import { Parser } from 'html-to-react'
import {
  faTwitterSquare,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import {
  faTimes,
  faCheck,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Row,
  Col,
  Button
} from "react-bootstrap";
import Environment from "../../shared/Environment";
import BlogService from "../../services/BlogService";
import "./index.css";
import { Helmet } from "react-helmet";

function BlogDetails(props) {
  const {
    match: { params },
  } = props;

  const blogApiCall = new BlogService();

  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [selectedBlog, setSelectedBlog] = useState();
  const [tags, setTags] = useState([]);
  const [blogsCategories, setBlogsCategories] = useState([]);

  useEffect(() => {
    Promise.all([
      blogApiCall.getBlogCategories(),
      blogApiCall.getBlogById(params.id),
    ])
      .then(([blogCategories, selectedBlogRes]) => {
        setSelectedBlog(selectedBlogRes.post);
        setBlogsCategories(blogCategories);
        var tags = selectedBlogRes.post.tags.split(' ');
        setTags(tags)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>

      <div className="breadcrumb-area-blog">
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <div className="breadcrumb-content">
                <h1 className="breadcrumb-hrading">Blog </h1>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div>
        <Container fluid className="inner-page-container-main">
          <Row>
            <Col sm={3} className="blog-page-side-heading">
              <h2>Categories</h2>
              <div className="category-post">
                <ul>
                  {blogsCategories && blogsCategories.length > 0
                    ? blogsCategories.map((cat) => {
                      return (
                        <li key={`blog_cat${cat.id}`} style={{ marginBottom: "5px !important" }}>
                          <Link to={`/blog/${cat.id}`}>{cat.name}</Link>
                        </li>
                      );
                    })
                    : ""}
                </ul>
              </div>
            </Col>
            <Col sm={9}>
              {selectedBlog ? (
                <Row>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{selectedBlog.title} - Five Element "Food from Heaven"</title>
                    <link rel="canonical" href={"https://www.fiveelementorganic.com/blogdetails/" + selectedBlog.blog_url} />
                    <meta
                      name="keywords"
                      content={selectedBlog.title}
                    />
                    <meta
                      name="description"
                      content={selectedBlog.title}
                    />
                  </Helmet>
                  <Col sm={12}>
                    <div className="single-blog-post blog-grid-post">
                      <div className="blog-post-media">
                        <div className="blog-image" style={{ textAlign: 'center' }}>
                          <img
                            src={`${imagePath}${selectedBlog.image_path}`}
                            style={{ width: '70%' }}
                            alt="blog"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    className="align-self-center align-items-center blog-individual-page"
                  >
                    <div className="blog-post-content-inner">
                      <h4 className="blog-title">
                        <a href="#">{selectedBlog.title}</a>
                      </h4>
                      {Parser().parse(selectedBlog.content)}
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Col sm={12}>
                <hr />
                {tags.map(item => <a href='javascript:void(0)'
                  type="button"
                  className="m-2"> {item} </a>)}
              </Col>
              <Col sm={12}>
                {selectedBlog ? (
                  <div className="single-wedge">
                    <br />
                    <h4 className="footer-herading">Share this:</h4>
                    <div className="social-info">
                      <ul>
                        <li>
                          <a
                            href={"https://www.facebook.com/sharer/sharer.php?u=" + "https://www.fiveelementorganic.com/blogdetails/" + selectedBlog.blog_url}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faFacebook} />
                          </a>
                        </li>
                        <li>
                          <a
                            href={"http://www.linkedin.com/shareArticle?mini=true&url=" + "https://www.fiveelementorganic.com/blogdetails/" + selectedBlog.blog_url}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faLinkedin} />
                          </a>
                        </li>
                        <li>
                          <a
                            href={"https://twitter.com/intent/tweet?url=" + "https://www.fiveelementorganic.com/blogdetails/" + selectedBlog.blog_url}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faTwitterSquare} />
                          </a>
                        </li>
                        <li>
                          <a
                            href={"https://api.whatsapp.com/send?text=" + "https://www.fiveelementorganic.com/blogdetails/" + selectedBlog.blog_url}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faWhatsapp} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>) : ''}
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default BlogDetails;
