import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faMapMarker,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import Environment from "../../shared/Environment";
import AuthService from "../../services/AuthService";
import ToastCom from "../common/toasts";
import "./index.css";
import { Helmet } from "react-helmet";

function Formers(props) {
  const authApiCall = new AuthService();
  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [searchForm, setSearcForm] = useState({
    id: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [result, setResults] = useState({ name: "" })


  function handleFormInput(e) {
    const { name, value } = e.target;
    setSearcForm((contactForm) => ({
      ...contactForm,
      [name]: value,
    }));
  }

  function search(e) {
    e.preventDefault();
    setButtonLoader(true);
    authApiCall
      .searchFormerDetails(searchForm)
      .then((data) => {
        setResults(data)
        setTimeout(() => {
          setButtonLoader(false);
        }, 3000);
      })
      .catch((err) => {
        setButtonLoader(false);
        setShowToast(true);
        setToastType("error");
        setToastMessage(err.data.error);
      });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Know Our Farmer - Five Element "Food from Heaven"</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/know-our-farmer" />
        <meta
          name="keywords"
          content="Know Our Farmer - Five Element 'Food from Heaven'"
        />

        <meta
          name="description"
          content="Know Our Farmer - Five Element 'Food from Heaven'"
        />
      </Helmet>
      <div className="breadcrumb-former">
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <div className="breadcrumb-content">
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center former-page">
          <Col sm={12}>
            <h2 className="form-header">Our Movement</h2>
            <div className="text-center">
              <img
                src={"assets/border-line.png"}
                className="bottom-border-line"
              />
            </div>
            <p>
              In order to protect the environment and maintaining proper balance in the eco-system <b>Five Element – "Food from Heaven"</b> as initiated a moment called <b>“Back to Roots”</b>. This movement was started and directly supervised by our founder Mr. Sridhar IPS (retd). The main objective of this movement is to support, educate and help farmers who have a passion for cultivating traditional crops through age old organic methods.</p>
            <p>
              As per the recent survey report by the Tamil Nadu agriculture university, the number of farmers converting from organic method of cultivation to conventional method of cultivation are seen having cultivation areas of 4-10 acres. The farmers who have been shortlisted in this movement are having agriculture land between 4-10 acres.
              These farmers have been offered a contract such that whatever they cultivate will be procured completely by us, in which 400+ farmers have been volunteered as of now. This contract-based procurement is done to support and protect the farmers and also to ensure the farmer that whatever they cultivate in their agricultural land will be sold completely.
            </p>
            <div className="former-page mb-30">
              <h2 className="form-header">
                For specific information  about your product
              </h2>
              <div className="text-center">
                <img
                  src={"assets/border-line.png"}
                  className="bottom-border-line"
                />
              </div>
            </div>
          </Col>
          <Col sm={8}>
            <div className="row">
              <div className="col-lg-10 col-sm-10" style={{ marginBottom: '10px' }}>
                <input
                  name="id"
                  value={searchForm.id}
                  onChange={handleFormInput}
                  placeholder="Enter the bar code"
                  className="form-control"
                  type="text"
                  required
                />
              </div>
              <div className="col-lg-2
               col-sm-3">
                <Button variant="primary" className="btn-lg btn-success btn-block" onClick={search}>Search</Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center former-page">
          {result && result["name"].length > 0 ?
            (<Col>
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <img
                    src={"https://5elements-api.fiveelementorganic.com/public/images/previewImages/" + `${result.product_image}`}
                    alt="Former Image"
                    className="d-block w-100"
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <label className="former-label" style={{ marginTop: '0px' }}>Name:</label> {result.name} <br />
                  <label className="former-label">Age:</label>  {result.age}  <br />
                  <label className="former-label">Year of Forming:</label>  {result.year_of_forming}  <br />
                  <label className="former-label">Forming Area:</label>  {result.farming_area}  <br />
                  <label className="former-label">Duration of Crop:</label>  {result.name}  <br />
                  <label className="former-label">Crop Cultivated:</label>  {result.crop_cultivated}  <br />
                  <label className="former-label">Types of Processing:</label>  {result.type_of_proccessing}  <br />
                  <label className="former-label">District:</label>  {result.district}  <br />
                  <label className="former-label">Formers Story:</label>  {result.content.replace(/\\/g, '')} <br />
                </div>
              </div>
            </Col>) : <></>
          }
        </Row>
        <Row>
          <Col><img
            src={"assets/formers/1.png"}
            alt=""
            className="d-block w-100"
          /></Col>
          <Col><img
            src={"assets/formers/2.png"}
            alt=""
            className="d-block w-100"
          /></Col>
          <Col><img
            src={"assets/formers/3.png"}
            alt=""
            className="d-block w-100"
          /></Col>
        </Row>
        <br />
        <Row>
          <Col><img
            src={"assets/formers/4.png"}
            alt=""
            className="d-block w-100"
          /></Col>
          <Col><img
            src={"assets/formers/5.png"}
            alt=""
            className="d-block w-100"
          /></Col>
          <Col><img
            src={"assets/formers/6.png"}
            alt=""
            className="d-block w-100"
          /></Col>
        </Row>
        <ToastCom
          showToast={showToast}
          toastType={toastType}
          toastMessage={toastMessage}
          setShowToast={setShowToast}
        ></ToastCom>
      </Container>

    </>
  );
}

export default Formers;
