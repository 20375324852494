import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Container,
  InputGroup,
  FormControl,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { Image } from "semantic-ui-react";
import ProductsServices from "../../services/ProductsServices";
import Environment from "../../shared/Environment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as cartAction from "../../redux/actions/cartAction";
import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};
const images = [
  "https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550223640-23097fc71cb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550353175-a3611868086b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550330039-a54e15ed9d33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1549737328-8b9f3252b927?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1549833284-6a7df91c1f65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1549985908-597a09ef0a7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
];

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const RelatedProducts = (props, { deviceType }) => {
  const productsApiCall = new ProductsServices();
  const [allProducts, setAllProducts] = useState([]);
  const [imagePath, setImagePath] = useState(Environment.image_url);

  useEffect(() => {
    let locationId = localStorage.getItem("currentCityid")
      ? localStorage.getItem("currentCityid")
      : "1";
    let params = {
      categoryId: props.categoryId,
      locationId: locationId,
      productId: props.productId,
    };
    productsApiCall
      .getRelatedProducts(params)
      .then((productDetails) => {
        let newProductDetails = productDetails.map((p) => ({
          ...p,
          selectedProductUnit: p.product_pricings[0],
        }));
        setAllProducts(newProductDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.productId]);

  return (
    <Carousel
      ssr
      partialVisbile
      deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
    >
      {/* {images.slice(0, 5).map((image) => {
        return (
          <div style={{ marginRight: "15px" }}>
            <Image
              draggable={false}
              style={{ width: "100%", height: "100%" }}
              src={image}
            />
          </div>
        );
      })} */}
      {allProducts.slice(0, 5).map((pro) => {
        let productImages = JSON.parse(pro.product_images);
        return (
          <div
            className="card list-product-card list-product-card-main"
            style={{ width: "20rem", maxHeight: "400px" }}
          >
            <Image
              src={`${imagePath}${productImages[0].name}`}
              style={{ width: "100%", height: "100%" }}
            />
            <div className="card-body">
              <Card.Title
                href={`/product/${pro.product_code}`}
                as={Link}
                to={`/product/${pro.product_code}`}
                className="product-link"
              >
                {pro.product_name}
              </Card.Title>
              <Card.Text className="inner-link" style={{ marginTop: "10px" }}>
                {pro.category}
              </Card.Text>
              <Card.Text>
                MRP. {pro.product_pricings[0].selling_price} (
                {pro.product_pricings[0].net_weight}
                {pro.product_pricings[0].unit_type})
              </Card.Text>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default RelatedProducts;
