export const LOAD_CART_SUCCESS = "LOAD_CART_SUCCESS";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const UPDATE_ITEM_CART_SUCCESS = "UPDATE_ITEM_CART_SUCCESS";
export const EMPTY_CART_SUCCESS = "EMPTY_CART_SUCCESS";

export const LOAD_ADDRESS_SUCCESS = "LOAD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const REMOVE_ADDRESS_SUCCESS = "REMOVE_ADDRESS_SUCCESS";
export const GET_ADDRESS_BY_ID_SUCCESS = "GET_ADDRESS_BY_ID_SUCCESS";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";

export const LOAD_LOGINUSER_SUCCESS = "LOAD_LOGINUSER_SUCCESS";
export const LOGOUT_LOGINUSER_SUCCESS = "LOGOUT_LOGINUSER_SUCCESS";
export const SEND_LOGINUSER_SUCCESS = "SEND_LOGINUSER_SUCCESS";
