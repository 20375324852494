import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faMapMarker,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  fab,
  faTwitterSquare,
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import Environment from "../../shared/Environment";
import AuthService from "../../services/AuthService";
import ToastCom from "../common/toasts";
import "./index.css";

function PrivacyPolicy(props) {
  const authApiCall = new AuthService();
  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState();

  function handleFormInput(e) {
    const { name, value } = e.target;
    setLoginData((loginData) => ({
      ...loginData,
      [name]: value,
    }));
  }

  function userLogin(e) {
    e.preventDefault();
  }

  return (
    <>
      <div className="breadcrumb-area-contact">
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <div className="breadcrumb-content">
                <h1 className="breadcrumb-hrading">Privacy Policy</h1>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Col className="privacy-area">
        <div className="container">
          <p className="terms-conditions">
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase from
            fiveelementorganic.com
          </p>
          <h6 className="header-page">Personal information we collect</h6>
          <p className="terms-conditions">
            When you visit the Site, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages or products that
            you view, what websites or search terms referred you to the Site,
            and information about how you interact with the Site. We refer to
            this automatically collected information as “Device Information”.
          </p>
          <p className="terms-conditions">
            We collect Device Information using the following technologies:
            <br />
            - “Cookies” are data files that are placed on your device or
            computer and often include an anonymous unique identifier.
            <br />- “Log files” track actions occurring on the Site, and collect
            data including your IP address, browser type, Internet service
            provider, referring/exit pages, and date/time stamps.
            <br />- “Web beacons”, “tags”, and “pixels” are electronic files
            used to record information about how you browse the Site.
          </p>
          <p className="terms-conditions">
            Additionally, when you make a purchase or attempt to make a purchase
            through the Site, we collect certain information from you, including
            your name, billing address, shipping address, payment information
            (including credit card numbers), email address, and phone number. We
            refer to this information as “Order Information”.
          </p>
          <p className="terms-conditions">
            When we talk about “Personal Information” in this Privacy Policy, we
            are talking both about Device Information and Order Information.
          </p>
          <h6 className="header-page">
            How do we use your personal information?
          </h6>
          <p className="terms-conditions">
            We use the Order Information that we collect generally to fulfil any
            orders placed through the Site (including processing your payment
            information, arranging for shipping, and providing you with invoices
            and/or order confirmations). Additionally, we use this Order
            Information to:
            <br />
            - Communicate with you;
            <br />
            - Screen our orders for potential risk or fraud;
            <br />- When in line with the preferences you have shared with us,
            provide you with information or advertising relating to our products
            or services.
          </p>
          <p className="terms-conditions">
            We use the Device Information that we collect to help us screen for
            potential risk and fraud (in particular, your IP address), and more
            generally to improve and optimize our Site (for example, by
            generating analytics about how our customers browse and interact
            with the Site, and to assess the success of our marketing and
            advertising campaigns).
          </p>
          <h6 className="header-page">Do not track</h6>
          <p className="terms-conditions">
            Please note that we do not alter our Site’s data collection and use
            practices when we see a Do Not Track signal from your browser.
          </p>
          <h6 className="header-page">Your rights</h6>
          <p className="terms-conditions">
            You have the right to access personal information we hold about you
            and to ask that your personal information be corrected, updated, or
            deleted. If you would like to exercise this right, please contact us
            through the contact information below.
          </p>
          <h6 className="header-page">Data retention </h6>
          <p className="terms-conditions">
            When you place an order through the Site, we will maintain your
            Order Information for our records unless and until you ask us to
            delete this information.
          </p>
          <h6 className="header-page">Changes</h6>
          <p className="terms-conditions">
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </p>
          <h6 className="header-page">Minors</h6>
          <p className="terms-conditions">
            The Site is not intended for individuals under the age of 18.
          </p>
          <h6 className="header-page">Contact us</h6>
          <p className="terms-conditions">
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by email at care@fiveelementorganic.com or by mail using the
            details provided below: <br />
            <br />
            S2 Organic Foods India, <br />
            74, Sundaram Street,
            <br /> Chinmaya nagar Stage 1,
            <br />
            Koyembedu, Chennai-92, India
          </p>
        </div>
      </Col>
    </>
  );
}

export default PrivacyPolicy;
