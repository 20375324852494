import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Animated } from "react-animated-css";
import ProductsServices from "../../services/ProductsServices";
import Environment from "../../shared/Environment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as cartAction from "../../redux/actions/cartAction";
import "./index.css";
import RelatedProducts from "./relatedProduct";
import "semantic-ui-css/semantic.min.css";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation } from "react-router";

function Product(props) {
  const {
    match: { params },
  } = props;

  const productsApiCall = new ProductsServices();
  const [selectedProduct, setSelectedProduct] = useState();
  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [enteredQuantity, setEnteredQuantity] = useState(1);
  const [productImages, setProductImages] = useState([]);
  const [selectedProductUnit, setSelectedProductUnit] = useState();
  const [activeTab, setActiveTab] = useState("desc");
  const [loadBannerImage, setLoadBannerImage] = useState("");
  const [loadBannerText, setLoadBannerText] = useState("");
  const [showAnimate, setShowAnimate] = useState(false);
  useEffect(() => {
    setShowAnimate(false);
    Promise.all([
      props.loadCartItems(),
      productsApiCall.getProductsById(params.product_name),
    ])
      .then(([cartItems, productDetails]) => {
        setShowAnimate(true);
        productDetails.health_benefits = JSON.parse(
          productDetails.health_benefits
        );
        setSelectedProduct(productDetails);
        setSelectedProductUnit(productDetails.product_pricings[0]);
        setProductImages(JSON.parse(productDetails.product_images));
        setLoadBannerImage(productDetails.banner_image_path);
        setLoadBannerText(productDetails.description);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.product_name]);

  const addtoCart = (proDetails) => {
    if (!enteredQuantity || enteredQuantity == "" || enteredQuantity == 0) {
      setEnteredQuantity(1);
    }

    let imageData = JSON.parse(proDetails.product_images);
    let sendItem = {
      brand: proDetails.brand,
      category: proDetails.category,
      // description: proDetails.description,
      id: proDetails.id,
      image_1: imageData[0].name,
      product_name: proDetails.product_name,
      unit_type_id: selectedProductUnit.id,
      selling_price: selectedProductUnit.selling_price,
      gst: selectedProductUnit.gst,
      net_weight: selectedProductUnit.net_weight,
      sub_category: proDetails.sub_category,
      unit_type: selectedProductUnit.unit_type,
    };

    props
      .addItemtoCart(sendItem, 1)
      .then((result) => {
        // setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function updateEnteredQuantity(e) {
    setEnteredQuantity(e);
  }

  const decreaseQuantity = (getCartItem) => {
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) - 1;
    props
      .decreaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const increaseQuantity = (getCartItem) => {
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) + 1;
    props
      .increaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function LoadItemQuantity(selectedProduct) {
    var selectedProductGet = selectedProduct.thisProduct;
    let selectedthisProductUnit = selectedProduct.thisProductUnit;

    let productExistsInCart = props.cartitems.some(
      (item) =>
        item.id == selectedProductGet.id &&
        item.unit_type_id == selectedthisProductUnit.id
    );

    let productInCart = props.cartitems.find(
      (item) =>
        item.id === selectedProductGet.id &&
        item.unit_type_id == selectedthisProductUnit.id
    );
    return productExistsInCart ? (
      <div className="add-to-cart-single-container">
        <Form.Control
          size="sm"
          as="select"
          name="product_price"
          className="product_price_unit_single"
          onChange={(e) => {
            let selectedPriceObject =
              selectedProductGet.product_pricings.filter((p) => {
                return p.id == e.target.value;
              });
            setSelectedProductUnit(selectedPriceObject[0]);
          }}
        >
          {selectedProductGet.product_pricings.map((pri) => {
            return (
              <option
                value={pri.id}
                key={`option1_${pri.id}`}
              >{`${pri.net_weight} ${pri.unit_type}`}</option>
            );
          })}
        </Form.Control>
        <InputGroup className="cart-change-individual-product">
          <InputGroup.Prepend>
            <InputGroup.Text onClick={() => decreaseQuantity(productInCart)}>
              -
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            disabled
            id="inlineFormInputGroup"
            placeholder=""
            value={productInCart.quantity}
            className="cart-change-individual-product-input"
          />
          <InputGroup.Prepend>
            <InputGroup.Text onClick={() => increaseQuantity(productInCart)}>
              +
            </InputGroup.Text>
          </InputGroup.Prepend>
        </InputGroup>
      </div>
    ) : (
      <div className="add-to-cart-single-container">
        <Form.Control
          size="sm"
          as="select"
          name="product_price"
          className="product_price_unit_single"
          defaultValue={selectedProductUnit.id}
          onChange={(e) => {
            let selectedPriceObject =
              selectedProductGet.product_pricings.filter((p) => {
                return p.id == e.target.value;
              });
            setSelectedProductUnit(selectedPriceObject[0]);
          }}
        >
          {selectedProductGet.product_pricings.map((pri) => {
            return (
              <option
                value={pri.id}
                key={`option2_${pri.id}`}
              >{`${pri.net_weight} ${pri.unit_type}`}</option>
            );
          })}
        </Form.Control>
        {/* <FormControl
          id="inlineFormInputGroup"
          placeholder="Quantity"
          className="custom-quantity-individual-product"
          type="number"
          name="productQuantity"
          value={enteredQuantity}
          onChange={updateEnteredQuantity}
        /> */}
        <span className="pro-details-cart btn-hover">
          <Button type="button" onClick={() => addtoCart(selectedProductGet)}>
            {" "}
            + Add To Cart
          </Button>
        </span>
      </div>
    );
  }

  function changeTab(getVal) {
    setActiveTab(getVal);
  }

  return (
    <>

      <Container className="inner-page-container-main">
        {selectedProduct ? (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Best place to buy Organic {selectedProduct.product_name}  - Five Element "Food from Heaven"</title>
              <link rel="canonical" href={"https://www.fiveelementorganic.com/product/" + selectedProduct.product_name} />
              <meta
                name="keywords"
                content={selectedProduct.product_name}
              />
              <meta
                name="description"
                content={selectedProduct.prod_description}
              />
            </Helmet>
            <Row>
              <Col sm={6}>
                <div className="product-details-img product-details-tab">
                  <Carousel
                    showArrows={false}
                    dynamicHeight={true}
                    showIndicators={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    showStatus={false}
                    className="carousel2"
                  >
                    {productImages && productImages.length > 0
                      ? productImages.map((pimg) => {
                        return (
                          <div key={`price_image_single_${pimg.name}`}>
                            <img src={`${imagePath}${pimg.name}`} />
                          </div>
                        );
                      })
                      : ""}
                  </Carousel>
                </div>
              </Col>
              <Col sm={6}>
                <div className="product-details-content">
                  <h2>{selectedProduct.product_name}</h2>
                  <div className="product-detail-content">
                    <p className="reference">
                      Category :<span> {selectedProduct.category}</span>
                    </p>
                    <p className="reference">
                      Brand :<span> {selectedProduct.brand}</span>
                    </p>
                    <div className="pricing-meta">
                      <ul>
                        <li className="old-price not-cut">
                          {selectedProductUnit ? (
                            <>
                              {selectedProductUnit.unit_price !==
                                selectedProductUnit.selling_price ? (
                                <span
                                  key={`pricetag_${selectedProductUnit.id}`}
                                  className="letterStrike"
                                >
                                  Rs. {selectedProductUnit.unit_price}
                                </span>
                              ) : (
                                ""
                              )}{" "}
                              <span>
                                Rs. {selectedProductUnit.selling_price}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="pro-details-quality mt-0px">
                      {selectedProduct.sold_out == "Yes" ? (
                        <Button
                          type="button"
                          className="out-of-cart-list-btn-list-single btn-block"
                        >
                          Out of stock
                        </Button>
                      ) : (
                        <>
                          <div className="cart-plus-minus">
                            {selectedProductUnit && selectedProduct ? (
                              <LoadItemQuantity
                                thisProduct={selectedProduct}
                                thisProductUnit={selectedProductUnit}
                              ></LoadItemQuantity>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="description-review-area mb-60px">
                  <div className="container">
                    <div className="description-review-wrapper">
                      <div className="description-review-topbar nav">
                        <a
                          className={activeTab == "combo" ? "active" : ""}
                          onClick={() => changeTab("combo")}
                          style={{
                            display:
                              selectedProduct.product_type === "Combo"
                                ? ""
                                : "none",
                          }}
                        >
                          Products
                        </a>
                        <a
                          className={activeTab == "desc" ? "active" : ""}
                          onClick={() => changeTab("desc")}
                        >
                          Description
                        </a>
                        <a
                          className={activeTab == "heal" ? "active" : ""}
                          onClick={() => changeTab("heal")}
                          style={{
                            display:
                              selectedProduct.product_type !== "Combo"
                                ? ""
                                : "none",
                          }}
                        >
                          Health Benefits
                        </a>
                      </div>
                      <div className="tab-content description-review-bottom">
                        <div id="des-details2" className="tab-pane active">
                          <div className="product-anotherinfo-wrapper">
                            {activeTab == "desc" ? (
                              <p>{selectedProduct.prod_description}</p>
                            ) : activeTab === "combo" ? (
                              <ul>
                                {selectedProduct.combos.map((d, index) => (
                                  <li>
                                    {d.product_name} {d.net_weight}{" "}
                                    {d.unit_type_name} Rs. {d.discounted_price}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <ul>
                                {selectedProduct.health_benefits.map(
                                  (d, index) => (
                                    <li key={index}>{d.descriptions}</li>
                                  )
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="description-review-area mb-60px">
                  <div className="container">
                    <div className="description-review-wrapper"></div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Related Products</h3> <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <RelatedProducts
                  categoryId={selectedProduct.category_id}
                  productId={selectedProduct.id}
                ></RelatedProducts>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col sm={6}>
                <Skeleton className="skel" />
              </Col>
              <Col>
                <div className="product-details-content">
                  <h2><Skeleton /></h2>
                  <div className="product-detail-content">
                    <p className="reference">
                      <Skeleton />
                    </p>
                    <p className="reference">
                      <Skeleton />
                    </p>
                    <div className="pricing-meta">
                      <ul>
                        <li className="old-price not-cut">
                          <Skeleton />
                        </li>
                      </ul>
                    </div>
                    <div className="pro-details-quality mt-0px">
                      <Skeleton />
                    </div>
                  </div>
                </div>
              </Col>

            </Row>
          </>
        )}
      </Container>
    </>
  );
}

Product.propTypes = {
  cartitems: PropTypes.array.isRequired,
  addItemtoCart: PropTypes.func.isRequired,
  increaseCartItemQuantity: PropTypes.func.isRequired,
  decreaseCartItemQuantity: PropTypes.func.isRequired,
  loadCartItems: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    cartitems: state.cartitems,
  };
}

const mapDispatchToProps = {
  addItemtoCart: cartAction.addItemtoCart,
  increaseCartItemQuantity: cartAction.increaseCartItemQuantity,
  decreaseCartItemQuantity: cartAction.decreaseCartItemQuantity,
  loadCartItems: cartAction.loadCartItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
