import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import AuthService from "../../../services/AuthService";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function VerifyOtp(props) {
  const {
    match: { params },
  } = props;

  const authApiCall = new AuthService();
  const [verifyUserData, setverifyUserData] = useState({
    otp: "",
    user_id: params.id,
  });
  const [buttonLoader, setButtonLoader] = useState(false);

  function handleFormInput(e) {
    const { name, value } = e.target;
    setverifyUserData((verifyUserData) => ({
      ...verifyUserData,
      [name]: value,
    }));
  }

  function handleResendOtp(e) {
    e.preventDefault();
    var sendData = {
      user_id: verifyUserData.user_id,
    };
    console.log(sendData);
    authApiCall
      .resendOtp(sendData)
      .then((data) => {
        toast.success("OTP Resend Successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.data.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  function userVerify(e) {
    e.preventDefault();
    setButtonLoader(true);
    authApiCall
      .verifyUserOTP(verifyUserData)
      .then((data) => {
        toast.success("Your account is verified. You can login now.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          props.history.push("/user/login");
        }, 5000);
      })
      .catch((err) => {
        setButtonLoader(false);
        toast.error(err.data[0].Status, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  return (
    <>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center">
          <Col sm={6}>
            <Card>
              <Card.Header>Verify Account</Card.Header>
              <Card.Body>
                <Form onSubmit={userVerify}>
                  <Form.Group controlId="formBasicName">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter otp"
                      required
                      name="otp"
                      value={verifyUserData.otp}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <Button
                    className={`global-submit-btn ${
                      buttonLoader ? "loading-btn" : ""
                    }`}
                    type="submit"
                  >
                    {buttonLoader ? "Processing..." : "Submit"}
                  </Button>
                  <Button
                    className={`btn-primary ml-2`}
                    type="submit"
                    onClick={handleResendOtp}
                  >
                    Resend OTP
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default VerifyOtp;
