import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import {
  faTimes,
  faCheck,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import Environment from "../../shared/Environment";
import BlogService from "../../services/BlogService";
import "./CategoryBlog.css";

function CategoryBlog(props) {
  const {
    match: { params },
  } = props;

  const blogApiCall = new BlogService();

  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [blogsCategories, setBlogsCategories] = useState([]);

  useEffect(() => {
    Promise.all([
      blogApiCall.getBlogCategories(),
      blogApiCall.getRecentBlogsById(params.id),
    ])
      .then(([blogCategories, recentsBlogs]) => {
        setRecentBlogs(recentsBlogs);
        setBlogsCategories(blogCategories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.id]);

  return (
    <>
      <div className="breadcrumb-area-blog">
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <div className="breadcrumb-content">
                <h1 className="breadcrumb-hrading">Blog Detail</h1>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Container fluid className="inner-page-container-main">
        <Row>
          <Col sm={3} className="blog-page-side-heading">
            <h2>Categories</h2>
            <div className="category-post">
              <ul>
                {blogsCategories && blogsCategories.length > 0
                  ? blogsCategories.map((cat) => {
                    return (
                      <li key={`blog_cat${cat.id}`} style={{ marginBottom: "5px !important" }}>
                        <Link to={`/blog/${cat.id}`}>{cat.name}</Link>
                      </li>
                    );
                  })
                  : ""}
              </ul>
            </div>
          </Col>
          <Col sm={9}>
            {recentBlogs && recentBlogs.length > 0
              ? recentBlogs.map((blog) => {
                return (
                  <Row style={{ marginBottom: 10 }}>
                    <Col sm={5}>
                      <div className="single-blog-post blog-grid-post">
                        <div className="blog-post-media">
                          <div className="blog-image">
                            <a className="#">
                              <img
                                src={`${imagePath}${blog.image_path}`}
                                alt="blog"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col
                      sm={7}
                      className="align-self-center align-items-center"
                    >
                      <div className="blog-post-content-inner">
                        <h4 className="blog-title">
                          <Link to={`/blogdetails/${blog.blog_url}`}>
                            {blog.title}
                          </Link>
                        </h4>
                        <ul className="blog-page-meta">
                          <li>
                            <a href="#">
                              <FontAwesomeIcon
                                icon={faUserCircle}
                                className="ion-person"
                              />
                              Admin
                            </a>
                          </li>
                        </ul>
                        <p>{blog.shordescription}</p>
                        <Link
                          className="read-more-btn"
                          as={Link}
                          to={`/blogdetails/${blog.blog_url}`}
                        >
                          {" "}
                          Read More{" "}
                          <i className="ion-android-arrow-dropright-circle"></i>
                        </Link>
                      </div>
                    </Col>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                );
              })
              : ""}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CategoryBlog;
