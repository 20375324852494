import React, { useState } from "react";
import { Carousel, Button, Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import Slide1 from "../../assets/images/slider1.jpg";
import Slide2 from "../../assets/images/slider2.jpg";
import Slide3 from "../../assets/images/slider3.jpg";
import mobile1 from "../../assets/images/mob-01.jpg";
import mobile2 from "../../assets/images/mob-02.jpg";
import mobile3 from "../../assets/images/mob-03.jpg";


import "./carousel.css";

function HomeCarousel(props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Carousel
        className="home-banner-carousel"
        controls={false}
        indicators={true}
      >
        <Carousel.Item>
          <a
            href={`/products/7`}
            onClick={() => setExpanded(false)}
          >
            <img
              className="d-block w-100"
              src={Slide1}
              alt="Because absolutely no one does it better than Mothers
              Nature"
            />
          </a>
          <Carousel.Caption id="cap-one">
            <Animated
              animationIn="bounceInRight"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={2000}
              animationOutDuration={2000}
            >
              {/* <h3 style={{ color: "#6A6649" }}> */}
              {/* Organic Food; <br /> */}
              {/* Its What Our Grandparents Used To Call "Food" */}
              {/* </h3> */}
            </Animated>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <a
            href={`/products/1`}
            onClick={() => setExpanded(false)}
          >
            <img
              className="d-block w-100"
              src={Slide2}
              alt="Find the best selling organic products Natural & Organic
              Products"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a
            href={`/products/14`}
            onClick={() => setExpanded(false)}
          >
            <img
              className="d-block w-100"
              src={Slide3}
              alt="Find the best selling organic products Natural & Organic
              Products"
            />
          </a>
          <Carousel.Caption>
            <Animated
              animationIn="bounceInRight"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={2000}
              animationOutDuration={2000}
            >
              {/* <h3> */}
              {/* Find the best selling <br /> Natural & Organic Products */}
              {/* </h3> */}
              {/* <Button as={Link} to={`/products/1`} className="shop-now-btn"> */}
              {/* Shop Now */}
              {/* </Button> */}
            </Animated>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <Carousel
        className="home-banner-carousel-mobile"
        controls={false}
        indicators={false}
      >
        <Carousel.Item>
          <a
            href={`/products/7`}
            onClick={() => setExpanded(false)}
          >
            <img
              className="d-block w-100"
              src={mobile1}
              alt=" Because absolutely no one does it better than Mothers
              Nature"
            />
          </a>
          <Carousel.Caption style={{ top: "10%" }}>
            <Animated
              animationIn="bounceInRight"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={2000}
              animationOutDuration={2000}
            >
              {/* <h3 style={{ fontSize: "17px" }}>
                Organic Food; <br />
                Its What Our Grandparents Used To Call "Food"
              </h3> */}
            </Animated>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <a
            href={`/products/1`}
            onClick={() => setExpanded(false)}
          >
            <img
              className="d-block w-100"
              src={mobile2}
              alt="Find the best selling organic products Natural & Organic
              Products"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a
            href={`/products/14`}
            onClick={() => setExpanded(false)}
          >
            <img
              className="d-block w-100"
              src={mobile3}
              alt="Find the best selling organic products Natural & Organic
              Products"
            />
          </a>
        </Carousel.Item>
      </Carousel>
      <Container>
        <Row>
          <Col sm={12} style={{
            textAlign: 'center ',
            marginTop: '27px',
            marginBottom: '5px',
            display: 'flex',
            flexDirection: 'column',
            padding: '40px'
          }}>
            <h3 style={{ fontWeight: 700 }}>We are 5th Generation Farmers</h3> <br />
            <div className="text-center">
              <img
                src={"assets/border-line.png"}
                className="bottom-border-line"
              />
            </div>
            <p style={{ textAlign: 'center ' }}>We are from the delta region of Tamil Nadu, certified by Ecocert France for the National Programme of Organic Production (NPOP), National Organic Program (NOP/ USDA), and European Union (EU/EOS). We are committed to long-term agricultural practices and to share the true flavour of our organic farm products. We provide you with farm-fresh food selections. We take no shortcuts when it comes to quality, ensuring that no impurity reaches your kitchen counter.</p>
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default HomeCarousel;
