import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Container,
  InputGroup,
  FormControl,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./productsCarousel.css";
import ProductsServices from "../../services/ProductsServices";
import Environment from "../../shared/Environment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as cartAction from "../../redux/actions/cartAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function ProductsCarousel(props) {
  const productsApiCall = new ProductsServices();
  const [allProducts, setAllProducts] = useState([]);
  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [enteredQuantity, setEnteredQuantity] = useState(0);

  useEffect(() => {
    props
      .loadCartItems()
      .then((result) => { })
      .catch((err) => {
        console.log(err);
      });

    let locationId = localStorage.getItem("currentCityid")
      ? localStorage.getItem("currentCityid")
      : "1";
    productsApiCall
      .getRecentProducts(locationId)
      .then((productDetails) => {
        let newProductDetails = productDetails.map((p) => ({
          ...p,
          selectedProductUnit: p.product_pricings[0],
        }));
        setAllProducts(newProductDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addtoCart = (proDetails) => {
    if (!enteredQuantity || enteredQuantity == "" || enteredQuantity == 0) {
      updateEnteredQuantity(1);
    }

    let imageData = JSON.parse(proDetails.product_images);

    let sendItem = {
      brand: proDetails.brand,
      category: proDetails.category,
      // description: proDetails.description,
      id: proDetails.id,
      image_1: imageData[0].name,
      product_name: proDetails.product_name,
      unit_type_id: proDetails.selectedProductUnit.id,
      selling_price: proDetails.selectedProductUnit.selling_price,
      gst: proDetails.selectedProductUnit.gst,
      net_weight: proDetails.selectedProductUnit.net_weight,
      sub_category: proDetails.sub_category,
      unit_type: proDetails.selectedProductUnit.unit_type,
    };

    props
      .addItemtoCart(sendItem, 1)
      .then((result) => {
        setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function updateEnteredQuantity(e) {
    setEnteredQuantity(e);
  }

  const decreaseQuantity = (getCartItem) => {
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) - 1;
    props
      .decreaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const increaseQuantity = (getCartItem) => {
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) + 1;
    props
      .increaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ProductPrice = (props) => {
    return (
      <>
        {props.proDetails.unit_price !== props.proDetails.selling_price ? (
          <span
            key={`pricetag_${props.proDetails.id}`}
            className="letterStrike"
          >
            Rs. {props.proDetails.unit_price}
          </span>
        ) : (
          ""
        )}{" "}
        <span key={`pricetag_${props.proDetails.id}`}>
          Rs. {props.proDetails.selling_price}
        </span>
      </>
    );
  };

  return (
    <Container className="featured-products-container">
      <div className="section-title">
        <h2>Featured Products</h2>
        <div className="text-center">
          <img
            src={"assets/border-line.png"}
            className="bottom-border-line"
          />
        </div>
      </div>
      <Row>
        {allProducts.length > 0
          ? allProducts.map((pro) => {
            let productExistsInCart = props.cartitems.some(
              (item) =>
                item.id === pro.id &&
                item.unit_type_id == pro.selectedProductUnit.id
            );
            let productInCart = props.cartitems.find(
              (item) =>
                item.id === pro.id &&
                item.unit_type_id == pro.selectedProductUnit.id
            );
            let productImages = JSON.parse(pro.product_images);
            return (
              <Col md={6} lg={4} xl={3} xs={12} key={`allpro_${pro.id}`}>
                <Card className="list-product-card list-product-card-main">
                  <Card.Img
                    variant="top"
                    src={`${imagePath}${productImages[0].name}`}
                  />
                  <Card.Body>
                    <Card.Title
                      as={Link}
                      to={`/product/${pro.product_code}`}
                      className="product-link"
                    >
                      {pro.product_name}
                    </Card.Title>
                    <Card.Text className="inner-link">
                      {pro.category}
                    </Card.Text>
                    <Card.Text className="current-price">
                      <ProductPrice
                        proDetails={pro.selectedProductUnit}
                      ></ProductPrice>
                    </Card.Text>
                    {/* <div className="view-product-single">
                        <Button
                          type="button"
                          className="view-product-btn-single-icon"
                          as={Link}
                          to={`/product/${pro.id}`}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                      </div> */}
                    <Form.Control
                      size="sm"
                      as="select"
                      name="product_price"
                      className="product_price"
                      defaultValue={
                        productInCart
                          ? productInCart.unit_type_id
                          : pro.product_pricings[0].id
                      }
                      onChange={(e) => {
                        let selectedPriceObject = pro.product_pricings.filter(
                          (p) => {
                            return p.id == e.target.value;
                          }
                        );
                        let priceObject = selectedPriceObject[0];
                        setAllProducts(
                          allProducts.map((x) => {
                            if (x.id == pro.id) {
                              return {
                                ...x,
                                selectedProductUnit: priceObject,
                              };
                            } else {
                              return x;
                            }
                          })
                        );

                        productExistsInCart = props.cartitems.some(
                          (item) =>
                            item.id === pro.id &&
                            item.unit_type_id == priceObject.id
                        );
                        productInCart = props.cartitems.find(
                          (item) =>
                            item.id === pro.id &&
                            item.unit_type_id == priceObject.id
                        );
                      }}
                    >
                      {pro.product_pricings.map((pri) => {
                        return (
                          <option
                            value={pri.id}
                          >{`${pri.net_weight} ${pri.unit_type}`}</option>
                        );
                      })}
                    </Form.Control>
                    {pro.sold_out == "Yes" ? (
                      <Button
                        type="button"
                        className="out-of-cart-list-btn-list btn-block"
                      >
                        Out of stock
                      </Button>
                    ) : (
                      <>
                        {productExistsInCart &&
                          productInCart.unit_type_id ==
                          pro.selectedProductUnit.id ? (
                          <InputGroup className="cart-change-list-product">
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                onClick={() =>
                                  decreaseQuantity(productInCart)
                                }
                              >
                                -
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              disabled
                              id="inlineFormInputGroup"
                              placeholder=""
                              value={productInCart.quantity}
                              className="cart-change-list-product-input"
                            />
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                onClick={() =>
                                  increaseQuantity(productInCart)
                                }
                              >
                                +
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                          </InputGroup>
                        ) : (
                          <div className="add-to-cart-container">
                            {/* <FormControl
                                id="inlineFormInputGroup"
                                placeholder="Quantity"
                                className="mb-2 custom-list-individual-product custom-quantity-list-product"
                                type="text"
                                name="productQuantity"
                                maxLength={2}
                                onChange={updateEnteredQuantity}
                              /> */}
                            <Button
                              type="button"
                              className="add-to-cart-list-btn add-to-cart-list-btn-list btn-block"
                              onClick={() => addtoCart(pro)}
                            >
                              Add
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            );
          })
          : ""}
      </Row>
    </Container>
  );
}

ProductsCarousel.propTypes = {
  cartitems: PropTypes.array.isRequired,
  addItemtoCart: PropTypes.func.isRequired,
  increaseCartItemQuantity: PropTypes.func.isRequired,
  decreaseCartItemQuantity: PropTypes.func.isRequired,
  loadCartItems: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    cartitems: state.cartitems,
  };
}

const mapDispatchToProps = {
  addItemtoCart: cartAction.addItemtoCart,
  increaseCartItemQuantity: cartAction.increaseCartItemQuantity,
  decreaseCartItemQuantity: cartAction.decreaseCartItemQuantity,
  loadCartItems: cartAction.loadCartItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCarousel);
