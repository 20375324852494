import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import "./appBanner.css";

function AppBanner(props) {
  return (
    <Container style={{ minHeight: '400px', marginTop: '70px', marginBottom: '70px', backgroundColor: '#ffeae6', borderRadius: 5 }}>
      <Row>
        <Col sm={6} style={{ textAlign: 'center' }}>
          <img src={"assets/app-banner.png"} alt="Five Element Mobile Application" style={{ height: '400px' }} />
        </Col>
        <Col sm={6} style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '400px'
        }}>
          <h3 style={{ textAlign: 'center', lineHeight: 1.7 }}>Just with a simple tap, secure your healthy lifestyle with best certified organic products available.
          </h3>
          <h5> So go ahead and get it now!</h5>
          <div className="img_app" style={{ textAlign: 'center' }}>
            <a href="https://play.google.com/store/apps/details?id=com.s2foodsindia.fiveelements"><img src={"assets/google_play.png"} alt="Android app on Google Play" style={{ margin: '10px' }} /></a>
            <a href="https://play.google.com/store/apps/details?id=com.s2foodsindia.fiveelements"><img src={"assets/app_store.png"} alt="IOS app on App Store" style={{ margin: '10px' }} /></a>
          </div>
        </Col>

      </Row>
    </Container >
  );
}

export default AppBanner;
