import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Environment from "../../../shared/Environment";
import AuthService from "../../../services/AuthService";
import AddressService from "../../../services/AddressService";
import moment from "moment";
import "./orderDetail.css";

function OrderDetail(props) {
  const authApiCall = new AuthService();
  const addressApiCall = new AddressService();

  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState();

  useEffect(() => {
    const {
      match: { params },
    } = props;

    if (!authApiCall.loggedIn()) {
      props.history.push("/user/login");
    } else {
      addressApiCall
        .getMyOrderById(params.id)
        .then((getMyOrder) => {
          setSelectedOrderDetails(getMyOrder);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  function ProductDetails() {
    let productDetails = JSON.parse(selectedOrderDetails.product_details);
    return (
      <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>SNo</th>
            <th>Product</th>
            <th>Unit Price</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {productDetails && productDetails.length > 0 ? (
            productDetails.map((item, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td className="product-details-order">
                    <div>
                      <img
                        src={`${imagePath}${item.image_1}`}
                        className="product-image-order-page"
                      ></img>
                    </div>
                    <div>
                      <Link to={`/product/${item.id}`}>
                        <small>{item.product_name}</small>
                      </Link>
                      <br></br>
                      <small>{item.category}</small>
                      <br></br>
                      <small>{item.brand}</small>
                      <br></br>
                      <small>
                        {item.net_weight} {item.unit_type}
                      </small>
                    </div>
                  </td>
                  <td>Rs. {item.selling_price}</td>
                  <td>{item.quantity}</td>
                  <td>Rs. {parseInt(item.quantity) * item.selling_price}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6}>No items</td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }

  return (
    <>
      <Container fluid className="inner-page-container-main">
        <Row>
          <Col sm={9}>
            {selectedOrderDetails ? (
              <Card className="order-details-user">
                <Card.Header>
                  <Row>
                    <Col sm={5}>
                      <h4>{selectedOrderDetails.order_status}</h4>
                      <small>
                        {moment(selectedOrderDetails.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )}
                      </small>
                    </Col>
                    <Col sm={3}>
                      <h4>Total</h4>
                      <small>
                        Rs.{" "}
                        {parseFloat(selectedOrderDetails.sub_total) +
                          parseFloat(selectedOrderDetails.delivery_charges)}
                      </small>
                    </Col>
                    <Col sm={4} className="order-info">
                      <h4>ORDER # {selectedOrderDetails.id}</h4>
                      <small>
                        <a href="#">Invoice</a>
                      </small>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm={12}>
                      <ProductDetails></ProductDetails>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ) : (
              ""
            )}
          </Col>
          <Col sm={3}>
            <Row>
              <Col sm={12} className="order-details-actions-single">
                <Button
                  className="order-action-items"
                  type="button"
                  as={Link}
                  to="/my-orders"
                >
                  My Orders
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OrderDetail;
