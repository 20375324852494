import Environment from "../shared/Environment";

export default class AuthService {
  constructor() {
    this.baseURL = Environment.baseURL;
    this.fetch = this.fetch.bind(this);
    this.fetchWithoutToken = this.fetchWithoutToken.bind(this);
  }

  login(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/user/login`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      this.setToken(res.token);
      this.setUserDetails(res);
      return Promise.resolve(res);
    });
  }

  getLoginDetails() {
    let loginUser = localStorage.getItem("loggedinUserDetails");
    return Promise.resolve(JSON.parse(loginUser));
  }

  registerUser(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/user/register`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  verifyUserOTP(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/customer/verify-otp`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  resendOtp(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/resend-otp`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  changePassword(getData) {
    var finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/user/change-password`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  verifyUserOTPEmail(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/user/forget`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  resetUserPassword(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/user/reset`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  submitFeedback(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/user/feedback`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  submitEnquiry(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/user/contact`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  searchFormerDetails(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/former/byBatch`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();
    const tokenExpiry = this.getTokenExpiry();
    return !!token && !this.isTokenExpired(tokenExpiry); // handwaiving here
  }

  LoggedInUserDetails() {
    return this.fetch(
      `${this.flexstudioURL}/ApplicationUser/GetLoggedInUserDetails`,
      {
        method: "GET",
      }
    ).then((res) => {
      return Promise.resolve(res);
    });
  }

  ForgotPassword(getData) {
    var finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/Login/ForgotPassword`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  VerifyOTP(getData) {
    var finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/Login/VerifyOTP`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }


  ResetPassword(getData) {
    var finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.baseURL}/Login/ResetPassword`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  checkLockout(getData) {
    var finalData = JSON.stringify(getData);
    return this.fetchWithoutToken(`${this.authURL}/AuthAPI/Lockout`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  isTokenExpired(Expiry) {
    try {
      const tokenExpiry = Expiry;
      return false;
      //   if (tokenExpiry < Date.now() / 1000) {
      //     return true;
      //   } else return false;
    } catch (err) {
      return false;
    }
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem("loginToken", idToken);
    // localStorage.setItem("org_id", orgId);
  }

  setUserDetails(userDetails) {
    // Saves user token to localStorage
    localStorage.setItem("loggedinUserDetails", JSON.stringify(userDetails));
    // localStorage.setItem("org_id", orgId);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("loginToken");
  }

  setTokenExpiry(idTokenExpiry) {
    // Saves user token to localStorage
    localStorage.setItem("loginTokenExpiry", idTokenExpiry);
  }

  getTokenExpiry() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("loginTokenExpiry");
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("loginToken");
    localStorage.removeItem("loginTokenExpiry");
    localStorage.removeItem("loggedinUserDetails");
  }

  logoutUser() {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("loginTokenExpiry");
    localStorage.removeItem("loggedinUserDetails");
    return Promise.resolve();
  }

  getProfile() {
    return this.getToken();
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {};

    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken();
      headers["Accept"] = "application/json";
      headers["Access-Control-Allow-Origin"] = "*";
      headers["Content-Type"] = "application/json";
    } else {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
      headers["Accept"] = "application/json";
      headers["Access-Control-Allow-Origin"] = "*";
    }

    return fetch(url, {
      headers,
      crossDomain: true,
      ...options,
    })
      .then(this.processResponse)
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.status === 400) {
          throw response;
        } else if (response.status === 403) {
          throw response;
        } else if (response.status === 500) {
          window.location.href = "/InternalServerError";
        }
      });
  }

  fetchWithoutToken(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {};

    headers["Accept"] = "application/json";
    headers["Access-Control-Allow-Origin"] = "*";
    headers["Content-Type"] = "application/json";

    return fetch(url, {
      headers,
      crossDomain: true,
      ...options,
    })
      .then(this.processResponse)
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.status === 400) {
          throw response;
        } else if (response.status === 403) {
          throw response;
        } else if (response.status === 500) {
          window.location.href = "/InternalServerError";
        }
      });
  }

  processResponse(response) {
    return new Promise((resolve, reject) => {
      // will resolve or reject depending on status, will pass both "status" and "data" in either case
      let func;
      response.status < 400 ? (func = resolve) : (func = reject);
      if (response.status === 204) {
        response.then((data) =>
          func({ status: response.status, data: { status: "success" } })
        );
      } else if (response.status === 500) {
        window.location.href = "/InternalServerError";
      } else if (response.status === 401) {
        window.location.href = "/Unauthorized";
      } else if (response.status === 502) {
        window.location.href = "/ServerError";
      } else {
        response
          .json()
          .then((data) => func({ status: response.status, data: data }));
      }
    });
  }
}
