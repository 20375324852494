import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import "./staticAreaOrganic.css";

function StaticAreaOrganic(props) {
  return (
    <div style={{ marginRight: '80px' }}>
      <div className="single-static-organic-wrap">
        <Row className="organic-header">
          <Col>
            <h3 className="organic-header-title">Why Five Element?</h3>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-center">
              <div className="ssom-img">
                <img src={"assets/asset1.png"} alt="" className="img-responsive" />
              </div>
              <div className="single-static-organic-meta">
                <h4>Organic & Natural</h4>
                <p>Crafted from natural and organic products</p>
              </div>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-center">
              <div className="ssom-img">
                <img src={"assets/asset2.png"} alt="" className="img-responsive" />
              </div>
              <div className="single-static-organic-meta">
                <h4>Quality </h4>
                <p> Committed To Quality. Committed To You.</p>
              </div>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-center">
              <div className="ssom-img">
                <img src={"assets/asset3.png"} alt="" className="img-responsive" />
              </div>
              <div className="single-static-organic-meta">
                <h4>Healthy & Conscious Living</h4>
                <p>Self, mind and spirit are all supported by this formula.</p>
              </div>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-center">
              <div className="ssom-img">
                <img src={"assets/asset4.png"} alt="" className="img-responsive" />
              </div>
              <div className="single-static-organic-meta">
                <h4>Eco Friendly</h4>
                <p>We share our feelings towards life and the environment</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default StaticAreaOrganic;
