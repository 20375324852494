import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthService from "../../../services/AuthService";
import AddressService from "../../../services/AddressService";
import moment from "moment";
import "./index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MyOrders(props) {
  const authApiCall = new AuthService();
  const addressApiCall = new AddressService();

  const [myOrders, setMyOrders] = useState([]);
  const [modalShowCancelOrder, setModalShowCancelOrder] = useState(false);
  const [loggedinUserDetails, setLoggedinUserDetails] = useState();
  const [selectedOrder, setSelectedOrder] = useState();

  useEffect(() => {
    if (!authApiCall.loggedIn()) {
      props.history.push("/user/login");
    } else {
      let userDetails = JSON.parse(localStorage.getItem("loggedinUserDetails"));
      setLoggedinUserDetails(userDetails);

      addressApiCall
        .getMyOrdereAll()
        .then((getMyOrders) => {
          setMyOrders(getMyOrders.reverse());
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        className="global-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cancel order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel this order?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="out-of-cart-list-btn-list"
            onClick={cancelOrder}
          >
            Yes
          </Button>
          <Button
            type="button"
            className="cancel-no-btn"
            onClick={props.onHide}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function cancelOrder() {
    let sendData = { id: selectedOrder.id };
    addressApiCall
      .cancelOrder(sendData)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function cancelOrderDialog(getOrder) {
    setSelectedOrder(getOrder);
    setModalShowCancelOrder(true);
  }

  function retryPayment(getOrderDetails) {
    let sendData = { order_id: getOrderDetails.id };
    addressApiCall
      .retryPayment(sendData)
      .then((retryResponse) => {
        let totalAmount =
          parseFloat(getOrderDetails.sub_total) +
          parseFloat(getOrderDetails.delivery_charges);
        var options = {
          key: retryResponse.key,
          currency: "INR",
          amount: parseFloat(totalAmount).toFixed(2).toString(),
          order_id: retryResponse.reference_id,
          name: "Aswa",
          description: "checkout page",
          image: "/logo.png",
          handler: async function (response) {
            let updateOrderData = {
              razorpay_signature: response.razorpay_signature,
              razorpay_payment_id: response.razorpay_payment_id,
              order_id: getOrderDetails.id,
            };
            addressApiCall
              .updateOrderStatus(updateOrderData)
              .then((dataRes) => {
                props.history.push(
                  `/order/payment-success/${getOrderDetails.id}`
                );
              })
              .catch((err) => {
                console.log(err);
              });
          },
          prefill: {
            name: loggedinUserDetails.name,
            email: loggedinUserDetails.email,
            contact: loggedinUserDetails.mobile_number,
          },
          notes: {
            note_key_1: "Tea. Earl Grey. Hot",
          },
          theme: {
            color: "#F37254",
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          console.log(response);
        });
        rzp1.open();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Container fluid className="inner-page-container-main">
        <Row>
          <Col sm={12}>
            {myOrders && myOrders.length > 0
              ? myOrders.map((order) => {
                if (order.product_details) {
                  return (
                    <Card
                      className="order-details-user"
                      key={`order_details_card_${order.id}`}
                    >
                      <Card.Header>
                        <Row>
                          <Col sm={5}>
                            <h4>{order.order_status}</h4>
                            <small>
                              {moment(order.created_at).format(
                                "DD MMM YYYY h:mm a"
                              )}
                              <br />
                            </small>
                          </Col>
                          <Col sm={3}>
                            <h4>Total</h4>
                            <small>
                              Rs.{" "}
                              {parseFloat(order.sub_total) +
                                parseFloat(order.delivery_charges)}
                            </small>
                          </Col>
                          <Col sm={4} className="order-info">
                            <h4>ORDER # {order.id}</h4>
                            <small>
                              <a href="#">Invoice</a>
                            </small>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <Row className="order-details-container">
                          <Col sm={5}>
                            <h4>
                              <b style={{ borderBottom: "solid 1px #000" }}>
                                Delivery Address
                              </b>
                            </h4>
                            <div>
                              <small>
                                {order.delivery_address.name},{" "}
                                {order.delivery_address.mobile}
                              </small>
                              <br></br>
                              <small>{order.delivery_address.street}</small>
                              <br></br>
                              <small>
                                {order.delivery_address.landmark},{" "}
                                {order.delivery_address.state}
                              </small>
                              <br></br>
                              <small>
                                {order.delivery_address.city},{" "}
                                {order.delivery_address.pincode}
                              </small>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <h4>
                              <b style={{ borderBottom: "solid 1px #000" }}>
                                Payment Info
                              </b>
                            </h4>
                            <small>
                              Payment Mode : {order.modeOfPayment}
                            </small>
                            <br></br>
                            <small>
                              Payment Status :{" "}
                              {order.payment_status != null
                                ? order.payment_status
                                : "PENDING"}
                            </small>
                          </Col>
                          <Col sm={4} className="order-details-actions">
                            <Button
                              as={Link}
                              to={`/order-detail/${order.id}`}
                              className="order-action-items"
                            >
                              View Details
                            </Button>
                            {order.order_status == "Order Confirmed" ||
                              order.order_status == "Order Placed" ? (
                              <Button
                                type="button"
                                className="oai-btn out-of-cart-list-btn-list"
                                onClick={() => cancelOrderDialog(order)}
                              >
                                Cancel Order
                              </Button>
                            ) : (
                              ""
                            )}
                            {order.payment_status == "Payment Failed" ||
                              order.payment_status == "Payment Initiated" ? (
                              <Button
                                type="button"
                                className="order-action-items"
                                onClick={() => retryPayment(order)}
                              >
                                Retry Payment
                              </Button>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  );
                }
              })
              : ""}
          </Col>
        </Row>
        <MyVerticallyCenteredModal
          show={modalShowCancelOrder}
          onHide={() => setModalShowCancelOrder(false)}
        />
        <ToastContainer />
      </Container>
    </>
  );
}

export default MyOrders;
