import * as types from "./actionTypes";
import CartService from "../../services/CartService";

const cartItemsAPI = new CartService();

export function loadCartItemsSuccess(cartitems) {
  return { type: types.LOAD_CART_SUCCESS, cartitems };
}

export function addtoCartItemSuccess(cartitem) {
  return { type: types.ADD_TO_CART_SUCCESS, cartitem };
}

export function updateCartItemSuccess(cartitem) {
  return { type: types.UPDATE_ITEM_CART_SUCCESS, cartitem };
}

export function loadCartItems() {
  return function (dispatch) {
    return cartItemsAPI
      .getAllCartItems()
      .then((cartitems) => {
        dispatch(loadCartItemsSuccess(cartitems));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addItemtoCart(getProductDetails, enteredQuantity) {
  return function (dispatch) {
    return cartItemsAPI
      .addNewItemtoCart(getProductDetails, enteredQuantity)
      .then((response) => {
        dispatch(addtoCartItemSuccess(response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function increaseCartItemQuantity(getProductDetails, enteredQuantity) {
  return function (dispatch) {
    return cartItemsAPI
      .updateQuantityToCartItem(getProductDetails, enteredQuantity)
      .then((cartitems) => {
        dispatch(loadCartItemsSuccess(cartitems));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function decreaseCartItemQuantity(getProductDetails, enteredQuantity) {
  return function (dispatch) {
    return cartItemsAPI
      .updateQuantityToCartItem(getProductDetails, enteredQuantity)
      .then((cartitems) => {
        dispatch(loadCartItemsSuccess(cartitems));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function removeItemFromCart(getItem) {
  return function (dispatch) {
    return cartItemsAPI
      .removeItemFromCartService(getItem)
      .then((cartitems) => {
        dispatch(loadCartItemsSuccess(cartitems));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function emptyCartItems() {
  return function (dispatch) {
    return cartItemsAPI
      .emptyItemsFromCart()
      .then((cartitems) => {
        dispatch(loadCartItemsSuccess(cartitems));
      })
      .catch((error) => {
        throw error;
      });
  };
}
