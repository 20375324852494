import React, { useState, useEffect } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./categoriesFeatured.css";
import CategoriesService from "../../services/CategoriesService";
import Environment from "../../shared/Environment";

function FeaturedCategories(props) {
  const categoryApiCall = new CategoriesService();
  const [allCategories, setAllCategories] = useState([]);
  const [imagePath, setImagePath] = useState(Environment.image_url);

  useEffect(() => {
    categoryApiCall
      .getRecentCategories()
      .then((res) => {
        setAllCategories(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container className="featured-categories-container">
      <div className="section-title">
        <h2>Categories</h2>
        <div className="text-center">
          <img
            src={"assets/border-line.png"}
            className="bottom-border-line"
          />
        </div>
      </div>
      <Row>
        {allCategories.length > 0
          ? allCategories.map((pro) => {
            return (
              <Col
                md={4}
                lg={4}
                xl={4}
                xs={12}
                className="categories-card"
                key={`cat_${pro.id}`}
              >
                <Card className="bg-dark text-white">
                  <Card.Img
                    src={`${imagePath}${pro.image_path}`}
                    alt="Card image"
                  />
                  <Card.ImgOverlay className="image-overlay d-flex flex-column justify-content-center align-items-right">
                    <Card.Title className="text-center">
                      {pro.category_name}
                    </Card.Title>
                    <Card.Title className="card-sub-title text-center">
                      {pro.product_count} Products
                    </Card.Title>
                    <Link
                      to={`/products/${pro.id}`}
                      className="view-cat-product-btn"
                    >
                      Shop Now
                    </Link>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            );
          })
          : ""}
      </Row>
    </Container>
  );
}

export default FeaturedCategories;
