import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Accordion,
  Modal,
} from "react-bootstrap";
import AuthService from "../../services/AuthService";
import AddressService from "../../services/AddressService";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as cartAction from "../../redux/actions/cartAction";
import * as addressAction from "../../redux/actions/addressAction";
import ToastCom from "../common/toasts";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import EmptyCartImage from "./emptycart.png";
import WarningImage from "./warning.png";
import paymentIcon from "./payments.jpg";
import "./index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

function Checkout(props) {
  const authApiCall = new AuthService();
  const addressApiCall = new AddressService();

  const [orderDetails, setOrderDetails] = useState({
    products: [],
    delivery_charges: "",
    order_status: "Order_placed",
    order_amount: "",
    payment_status: "",
    modeOfPayment: "",
    DeliveryType: "",
    delivery_address: "",
    user_id: "",
  });
  const [showNewAddressModal, setShowNewAddressModal] = useState(false);
  const [areaCodeDetail, setareaCodeDetail] = useState({});

  const [newAddressForm, setNewAddressForm] = useState({
    id: "",
    name: "",
    mobile: "",
    street: "",
    city: "",
    state: "",
    area_name: "",
    landmark: "",
    pincode: "",
    user_id: "",
    otp: "",
  });
  const [showOtpVerificationModal, setShowOtpVerificationModal] =
    useState(false);

  const [userAddresses, setUserAddresses] = useState([]);
  const [deliveryPincodes, setDeliveryPincodes] = useState([]);

  const [pincodeChange, setPincodeChange] = useState();
  const [showPincodeError, setShowPincodeError] = useState(false);
  const [subTotal, setSubTotal] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAmountToDisplay, setTotalAmountToDisplay] = useState(0);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [loggedinUserDetails, setLoggedinUserDetails] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [allCity, setAllCity] = useState([]);
  const [terms, setTerms] = useState(true)

  useEffect(() => {
    // if (!authApiCall.loggedIn()) {
    //   props.history.push("/user/login");
    // } else {
    props
      .loadCartItems()
      .then((result) => { })
      .catch((err) => {
        console.log(err);
      });
    if (authApiCall.loggedIn()) {
      props
        .loadUserAddresses()
        .then((result) => { })
        .catch((err) => {
          console.log(err);
        });
    }

    addressApiCall
      .getMyCityAll()
      .then((res) => {
        setAllCity(res);
      })
      .catch((err) => {
        console.log(err);
      });

    let userDetails = JSON.parse(localStorage.getItem("loggedinUserDetails"));
    setLoggedinUserDetails(userDetails);

    Promise.all([addressApiCall.getDeliveryPincodes()])
      .then(([pincodes]) => {
        setDeliveryPincodes(pincodes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCloseAddressModal = () => setShowNewAddressModal(false);
  const handleShowAddressModal = () => setShowNewAddressModal(true);

  const handleCloseOTPModal = () => setShowOtpVerificationModal(false);
  const handleShowOTPModal = () => {
    // e.preventDefault();
    var sendData = newAddressForm;
    addressApiCall
      .sendOtp(sendData)
      .then((data) => {
        setShowOtpVerificationModal(true);
        toast.success(
          "OTP send successfully. Please check your mobile number",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      })
      .catch((err) => {
        toast.error(err.data.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  function handleNewAddressForm(e) {
    const { name, value } = e.target;
    setNewAddressForm((newAddressForm) => ({
      ...newAddressForm,
      [name]: value,
    }));
  }

  const onChangePincode = (selected) => {
    setPincodeChange(selected);
  };

  function onChangePincodeData(e) {
    const { name, value } = e.target;
    setNewAddressForm((newAddressForm) => ({
      ...newAddressForm,
      [name]: value,
    }));
    let sendData = {
      pincode: e.target.value,
    };
    if (e.target.value.length >= 6) {
      addressApiCall
        .pincodeValidation(sendData)
        .then((data) => {
          setShowPincodeError(false);
          setareaCodeDetail(data);
          // if (parseFloat(subTotal) < parseFloat(2000).toFixed(2)) {
          if (orderDetails.DeliveryType === "Express") {
            data.express_charge =
              data.express_charge === null ? "0" : data.express_charge;
            setDeliveryCharges(parseFloat(data.express_charge).toFixed(2));
          } else {
            data.normal_charge =
              data.normal_charge === null ? "0" : data.normal_charge;
            setDeliveryCharges(parseFloat(data.normal_charge).toFixed(2));
          }
          setNewAddressForm({
            ...newAddressForm,
            city: data.city_id,
            area_name: data.area_name,
            state: data.distance,
            pincode: data.pincode,
          });
          // }
        })
        .catch((err) => {
          setShowPincodeError(true);
          setDeliveryCharges(0);
        });
    }
  }

  function handleOtpForm(e) {
    const { name, value } = e.target;
    setNewAddressForm((newAddressForm) => ({
      ...newAddressForm,
      [name]: value,
    }));
  }

  function handleCouponCode(event) {
    event.preventDefault();
    var tem = event.target.value;
    setCouponCode(tem);
  }

  function verifyOtpForm(e) {
    e.preventDefault();
    var sendData = newAddressForm;
    addressApiCall
      .verifyOtp(sendData)
      .then((data) => {
        localStorage.setItem("loginToken", data["token"]);
        let loggedinUserDetails = {
          id: data["user"],
          name: "",
          email: "",
          token: data["token"],
        };
        localStorage.setItem(
          "loggedinUserDetails",
          JSON.stringify(loggedinUserDetails)
        );
        setTimeout(() => {
          completeOrder();
        }, 3000);
        setShowOtpVerificationModal(false);
      })
      .catch((err) => {
        toast.error(err.data.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  function handleResendOtp(e) {
    e.preventDefault();
    var sendData = newAddressForm;
    addressApiCall
      .resendOtp(sendData)
      .then((data) => {
        toast.success("OTP Resend Successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.data.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  function editAddress(getId, pincode, selectedAddress) {
    var selectedPincode = deliveryPincodes.filter(function (el) {
      return el.pincode == pincode;
    });
    setareaCodeDetail(selectedPincode[0]);
    if (parseFloat(subTotal) < parseFloat(2000).toFixed(2)) {
      setDeliveryCharges(
        parseFloat(selectedPincode[0].normal_charge).toFixed(2)
      );
    }
    let thisName = "delivery_address";
    setOrderDetails((orderDetails) => ({
      ...orderDetails,
      [thisName]: getId,
    }));
    setNewAddressForm(selectedAddress);
  }

  function saveNewAddress(e) {
    e.preventDefault();
    if (!pincodeChange) {
      setShowPincodeError(true);
      return false;
    }
    let userDetails = JSON.parse(localStorage.getItem("loggedinUserDetails"));
    let userId = userDetails.id;
    var sendData = newAddressForm;
    sendData.user_id = userId.toString();
    sendData.pincode = pincodeChange[0].pincode;
    props
      .addNewAddress(sendData)
      .then((result) => {
        setNewAddressForm({
          id: "",
          name: "",
          mobile: "",
          street: "",
          city: "",
          state: "",
          area_name: "",
          landmark: "",
          pincode: "",
          user_id: "",
          otp: "",
        });
        setShowNewAddressModal(false);
        toast.success("New address is added", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.data.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  function selectAddress(getId, pincode, selectedAddress) {
    var selectedPincode = deliveryPincodes.filter(function (el) {
      return el.pincode == pincode;
    });
    if (parseFloat(subTotal) < parseFloat(2000).toFixed(2)) {
      setDeliveryCharges(
        parseFloat(selectedPincode[0].normal_charge).toFixed(2)
      );
    }
    setareaCodeDetail(selectedPincode[0]);
    console.log(selectedPincode);
    console.log(areaCodeDetail);
    setNewAddressForm(selectedAddress);
    let thisName = "delivery_address";
    setOrderDetails((orderDetails) => ({
      ...orderDetails,
      [thisName]: getId,
    }));
  }

  function selectPaymentType(e) {
    let thisName = "modeOfPayment";
    setOrderDetails((orderDetails) => ({
      ...orderDetails,
      [thisName]: e.target.value,
    }));
  }

  function selectTerms(e) {
    // console.log(e.target.value)
    // console.log(terms)
    setTerms(e)
    console.log(terms)
  }

  function selectDeliveryType(e) {
    let thisName = "DeliveryType";
    setOrderDetails((orderDetails) => ({
      ...orderDetails,
      [thisName]: e.target.value,
    }));
    if (parseFloat(subTotal) < parseFloat(2000).toFixed(2)) {
      // console.log(orderDetails.DeliveryType);
      if (e.target.value === "Express") {
        areaCodeDetail.express_charge =
          areaCodeDetail.express_charge === null
            ? "0"
            : areaCodeDetail.express_charge;
        setDeliveryCharges(
          parseFloat(areaCodeDetail.express_charge).toFixed(2)
        );
      } else {
        areaCodeDetail.normal_charge =
          areaCodeDetail.normal_charge === null
            ? "0"
            : areaCodeDetail.normal_charge;
        setDeliveryCharges(parseFloat(areaCodeDetail.normal_charge).toFixed(2));
      }
    }
  }

  function getCouponAmount() {
    let discount_code = couponCode;
    if (discount_code == "") {
      toast.warn("Please enter the valid coupon code", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (newAddressForm.mobile == "") {
      toast.warn("Please enter address details to apply the coupon", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    let d_code = {
      discount_code: discount_code,
      order_amount: SubTotal(),
      mobile_number: newAddressForm.mobile,
    };
    addressApiCall
      .getOfferCodeDetails(d_code)
      .then((result) => {
        setDiscountAmount(parseInt(result));
      })
      .catch((err) => {
        setDiscountAmount(0);
        toast.error(err.data.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  function clearCouponCode() {
    setDiscountAmount(0);
    setCouponCode("");
  }

  function completeOrder() {
    console.log(terms)
    if (terms === false) {
      toast.warn("Please accept the terms & conditions", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false
    }
    if (orderDetails.modeOfPayment === "") {
      toast.warn("Please select the mode of payment", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (!authApiCall.loggedIn()) {
      if (
        newAddressForm.mobile == "" ||
        newAddressForm.pincode == "" ||
        newAddressForm.landmark == "" ||
        newAddressForm.city == ""
      ) {
        toast.warn("Please fill the address details to place the order", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        handleShowOTPModal();
      }
    } else {
      setButtonLoader(true);
      let userDetails = JSON.parse(localStorage.getItem("loggedinUserDetails"));
      let userId = userDetails.id;
      var sendData = orderDetails;
      sendData["address"] = newAddressForm;
      sendData.user_id = userId.toString();
      sendData.delivery_charges = deliveryCharges.toString();
      sendData.delivery_address = orderDetails.delivery_address.toString();
      sendData.products = JSON.stringify(props.cartitems);
      sendData["coupon_code"] = couponCode;
      sendData["coupon_amount"] = discountAmount;
      sendData["tax_amount"] = taxAmount;
      let totalAmount = 0;
      props.cartitems.map((car) => {
        totalAmount =
          totalAmount +
          parseFloat(car.selling_price).toFixed(2) * parseInt(car.quantity);
      });
      sendData.order_amount = (
        totalAmount +
        parseFloat(deliveryCharges) +
        parseFloat(taxAmount) -
        parseFloat(discountAmount)
      ).toString();
      addressApiCall
        .completeOrder(sendData)
        .then((data) => {
          if (sendData.modeOfPayment == "Online") {
            let finalAmount =
              parseFloat(deliveryCharges) +
              parseFloat(totalAmount) +
              parseFloat(taxAmount) -
              parseFloat(discountAmount);
            let orderId = data.order.toString();
            let checkoutData = {
              amount: parseFloat(finalAmount).toFixed(2),
              order_id: orderId,
            };
            addressApiCall
              .checkoutOrder(checkoutData)
              .then((checkoutDataRes) => {
                props
                  .emptyCartItems()
                  .then((result) => {
                    setIsOrderPlaced(true);
                    var options = {
                      key: checkoutDataRes.key,
                      currency: "INR",
                      amount: parseFloat(finalAmount).toFixed(2).toString(),
                      order_id: checkoutDataRes.reference_id,
                      name: newAddressForm.name,
                      description: "checkout page",
                      image: "/logo.png",
                      handler: async function (response) {
                        let updateOrderData = {
                          razorpay_signature: response.razorpay_signature,
                          razorpay_payment_id: response.razorpay_payment_id,
                          order_id: data.order,
                        };
                        addressApiCall
                          .updateOrderStatus(updateOrderData)
                          .then((dataRes) => {
                            props
                              .emptyCartItems()
                              .then((result) => {
                                props.history.push(
                                  `/order/notify/${data.order}`
                                );
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      },
                      prefill: {
                        name: newAddressForm.name,
                        email: "guest@fiveelementorganic.com",
                        contact: newAddressForm.mobile,
                      },
                      notes: {
                        note_key_1: "Order Checkout",
                      },
                      theme: {
                        color: "#F37254",
                      },
                    };
                    const rzp1 = new window.Razorpay(options);
                    rzp1.on("payment.failed", function (response) {
                      console.log(response);
                    });
                    rzp1.open();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                toast.error(err.data.error, {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          } else {
            props
              .emptyCartItems()
              .then((result) => {
                props.history.push(`/order/notify/${data.order}`);
                window.localStorage.clear();
              })
              .catch((err) => {
                console.log(err);
                toast.error(err.data.error, {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }
        })
        .catch((err) => {
          setButtonLoader(false);
          toast.error(err.data.error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  function SubTotal() {
    let totalAmount = 0;
    props.cartitems.map((car) => {
      totalAmount =
        totalAmount +
        parseFloat(car.selling_price).toFixed(2) * parseInt(car.quantity);
    });
    setSubTotal(parseFloat(totalAmount).toFixed(2));
    return parseFloat(totalAmount).toFixed(2);
  }

  function TaxTotal() {
    let totalTaxtAmount = 0;
    props.cartitems.map((car) => {
      let tax = 0;
      if (discountAmount > 0) {
        let avgDiscountPerProduct =
          parseFloat(discountAmount) / parseFloat(props.cartitems.length);
        console.log(avgDiscountPerProduct);
        let subTotal = 0;
        subTotal = car.subTotal - parseFloat(avgDiscountPerProduct);
        tax = (subTotal * car.gst) / 100;
        totalTaxtAmount = totalTaxtAmount + tax;
      } else {
        tax = (car.subTotal * car.gst) / 100;
        totalTaxtAmount = totalTaxtAmount + tax;
      }
    });
    let roundoff = Math.round(totalTaxtAmount.toFixed(2));
    setTaxAmount(roundoff);
    return parseFloat(roundoff);
  }

  function TotalAmount() {
    let totalAmount = 0;
    props.cartitems.map((car) => {
      totalAmount =
        totalAmount +
        parseFloat(car.selling_price).toFixed(2) * parseInt(car.quantity);
    });

    let finalAmount =
      parseFloat(totalAmount) +
      parseFloat(taxAmount) +
      parseFloat(deliveryCharges) -
      parseFloat(discountAmount);
    let formatedAmount = parseFloat(finalAmount).toFixed(2);
    return formatedAmount;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout - Five Element "Food from Heaven"</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/checkout" />
        <meta
          name="keywords"
          content="Checkout - Five Element 'Food from Heaven'"
        />
        <meta
          name="description"
          content="Checkout - Five Element 'Food from Heaven'"
        />
      </Helmet>
      <Container className="inner-page-container-main">
        {props.cartitems && props.cartitems.length > 0 ? (
          <Row className="checkout-page">
            <Col lg={7} md={12} className="ck-left">
              <div className="your-order-area yoa-left">
                <Card>
                  <Card.Header>
                    <div className="add-card">
                      <div className="addres-head">Shipping address</div>
                      {/* <Button
                        type="button"
                        className="add-new-address-btn pull-right btn-sm"
                        onClick={handleShowAddressModal}
                      >
                        Add New
                      </Button> */}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {props.userAddresses && props.userAddresses.length > 0 ? (
                      <Row>
                        <Col>
                          <br />
                          <Accordion defaultActiveKey="0">
                            <Card>
                              <Card.Header>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey="0"
                                >
                                  Choose from a list of pre-existing addresses
                                </Accordion.Toggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <Row className="address-cards ac-card">
                                    {props.userAddresses &&
                                      props.userAddresses.length > 0 ? (
                                      props.userAddresses.map(
                                        (address, index) => {
                                          return (
                                            <Col
                                              sm={6}
                                              key={`address_${address.id}`}
                                            >
                                              <Card
                                                className={
                                                  address.id ==
                                                    orderDetails.delivery_address
                                                    ? "selected-address"
                                                    : ""
                                                }
                                              >
                                                <Card.Header>
                                                  Address {index + 1}{" "}
                                                  <FontAwesomeIcon
                                                    icon={faEdit}
                                                    className="account-chevron float-right"
                                                    onClick={() =>
                                                      editAddress(
                                                        address.id,
                                                        address.pincode,
                                                        address
                                                      )
                                                    }
                                                  />
                                                </Card.Header>
                                                <Card.Body>
                                                  <div className="address-card">
                                                    <p>
                                                      {address.name},{" "}
                                                      {address.mobile}
                                                    </p>
                                                    <p>{address.street}</p>
                                                    <p>
                                                      {address.landmark},{" "}
                                                      {address.state}
                                                    </p>
                                                    <p>
                                                      {address.city},{" "}
                                                      {address.pincode}
                                                    </p>
                                                  </div>
                                                  <Button
                                                    type="button"
                                                    className="btn-primaary btn-block btn-sm"
                                                    onClick={() =>
                                                      selectAddress(
                                                        address.id,
                                                        address.pincode,
                                                        address
                                                      )
                                                    }
                                                  >
                                                    Choose
                                                  </Button>
                                                </Card.Body>
                                              </Card>
                                            </Col>
                                          );
                                        }
                                      )
                                    ) : (
                                      <p className="no-addr">
                                        No Address available
                                      </p>
                                    )}
                                  </Row>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <Form onSubmit={saveNewAddress}>
                      <Row>
                        <Col>
                          <br />
                          <h5 style={{ fontWeight: 500 }}>
                            Whether you'd like to add a new address?
                          </h5>
                          <hr />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Control
                            placeholder="Full name"
                            name="name"
                            value={newAddressForm.name}
                            onChange={handleNewAddressForm}
                            required
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            placeholder="Mobile number"
                            name="mobile"
                            value={newAddressForm.mobile}
                            onChange={handleNewAddressForm}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Form.Control
                            as="textarea"
                            placeholder="Address"
                            rows={3}
                            name="street"
                            value={newAddressForm.street}
                            onChange={handleNewAddressForm}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Form.Control
                            placeholder="Land mark"
                            name="landmark"
                            value={newAddressForm.landmark}
                            onChange={handleNewAddressForm}
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            placeholder="Pincode"
                            name="pincode"
                            value={newAddressForm.pincode}
                            onChange={onChangePincodeData}
                          />
                          {showPincodeError ? (
                            <Form.Text
                              id="pincodeHelpBlock"
                              style={{
                                color: "red",
                                fontSize: "13px",
                              }}
                            >
                              Please enter the valid pincode
                            </Form.Text>
                          ) : (
                            " "
                          )}
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Form.Control
                            placeholder="City"
                            name="city"
                            value={newAddressForm.city}
                            onChange={handleNewAddressForm}
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            placeholder="State"
                            name="state"
                            value={newAddressForm.state}
                            onChange={handleNewAddressForm}
                            disabled="true"
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <h6>
                            <br />
                            {props.userAddresses &&
                              props.userAddresses.length > 0 ? (
                              ""
                            ) : (
                              <>
                                Have you already registered? kindly{" "}
                                <a href="user/login">login</a>
                              </>
                            )}
                          </h6>
                        </Col>
                      </Row>
                    </Form>
                    <hr></hr>
                    <Row className="payment-select-div">
                      <div className="full-width-div">
                        <h3>Delivery Options</h3>
                      </div>
                      <div className="full-width-div">
                        <Accordion defaultActiveKey="0">
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="01">
                              <div
                                style={{ display: "flex", fontSize: "16px" }}
                              >
                                <Form.Check
                                  type="radio"
                                  id={`default-radio-express`}
                                  htmlFor={`default-radio-express`}
                                  name="DeliveryType"
                                  label={`Express Delivery`}
                                  value="Express"
                                  onChange={selectDeliveryType}
                                />
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="01">
                              <Card.Body>
                                <p className="payment-method-body">
                                  Within Chennai - Within 14 hours delivery
                                  <br />
                                  Other than Chennai - Within 3 days delivery
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="11">
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "16px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Check
                                  type="radio"
                                  id={`default-radio-normal`}
                                  htmlFor={`default-radio-normal`}
                                  label={`Normal Delivery`}
                                  name="DeliveryType"
                                  value="Normal"
                                  onChange={selectDeliveryType}
                                />
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="11">
                              <Card.Body>
                                <p className="payment-method-body">
                                  Within Chennai - Free delivery, delivered
                                  within 3 days <br />
                                  Other than Chennai - Within 6 days delivery
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </div>
                    </Row>
                    <hr />
                    <Row className="payment-select-div">
                      <div className="full-width-div" style={{ marginTop: 20 }}>
                        <h3>Payment Methods</h3>
                        <h5>100% Purchase Protection </h5>
                        <p>All transactions are secured and encrypted.</p>
                      </div>
                      <div className="full-width-div">
                        <Accordion>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <div
                                style={{ display: "flex", fontSize: "16px" }}
                              >
                                <Form.Check
                                  type="radio"
                                  id={`default-radio-cod`}
                                  htmlFor={`default-radio-cod`}
                                  name="modeOfPayment"
                                  label={`Cash on Delivery `}
                                  value="COD"
                                  onChange={selectPaymentType}
                                />
                                <h6></h6>
                              </div>
                            </Accordion.Toggle>
                            {/* <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <p className="payment-method-body">
                                  After clicking “Place order”, you will be
                                  redirected to Cash on Delivery to complete
                                  your purchase securely.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse> */}
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "16px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Check
                                  type="radio"
                                  id={`default-radio-online`}
                                  htmlFor={`default-radio-online`}
                                  label={`Online  `}
                                  name="modeOfPayment"
                                  value="Online"
                                  onChange={selectPaymentType}
                                />
                                {/* (Cards, UPI, NetBanking, Wallets) */}
                                <h6>
                                  <img
                                    src={paymentIcon}
                                    alt=""
                                    style={{ width: "80%", float: "right" }}
                                  />
                                </h6>
                              </div>
                            </Accordion.Toggle>
                            {/* <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <p className="payment-method-body">
                                  After clicking “Place order”, you will be
                                  redirected to Razorpay (Cards, UPI,
                                  NetBanking, Wallets) to complete your purchase
                                  securely..
                                </p>
                              </Card.Body>
                            </Accordion.Collapse> */}
                          </Card>
                        </Accordion>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col lg={5} md={12} className="ck-right">
              <div className="your-order-area">
                <Row>{/* <h3>Your order</h3> */}</Row>

                <div className="your-order-wrap ">
                  <div className="your-order-top">
                    <ul>
                      <li>Product</li>
                      <li>Total</li>
                    </ul>
                  </div>
                  <div className="your-order-middle">
                    <ul>
                      {props.cartitems && props.cartitems.length > 0
                        ? props.cartitems.map((item, index) => {
                          return (
                            <li>
                              <span className="order-middle-left">
                                <b>{item.product_name}</b> x {item.quantity}
                                <br />({item.net_weight} {item.unit_type})
                              </span>{" "}
                              <span className="order-price">
                                {" "}
                                ₹
                                {parseInt(item.quantity) *
                                  item.selling_price}{" "}
                              </span>
                            </li>
                          );
                        })
                        : ""}
                    </ul>
                  </div>
                  <div className="your-order-bottom">
                    <ul>
                      <li className="your-order-shipping">Sub Total</li>
                      <li>
                        Rs. <SubTotal></SubTotal>
                      </li>
                    </ul>
                  </div>
                  <hr />
                  <div className="your-order-bottom">
                    <ul>
                      <li>
                        <Form.Control
                          size="lg"
                          type="text"
                          placeholder="Coupon Code"
                          name="name"
                          value={couponCode}
                          onChange={handleCouponCode}
                          required
                        />
                        <p
                          className="helptext"
                          style={{ marginTop: "20px", fontSize: "12px" }}
                        >
                          {/* Rs {discountAmount} discount applied to your order{" "} */}
                          <a
                            href="javascript:void(0)"
                            onClick={clearCouponCode}
                          >
                            Clear Coupon
                          </a>
                        </p>
                      </li>
                      <li style={{ textAlign: "right", marginTop: "8px" }}>
                        <Button
                          type="button"
                          onClick={getCouponAmount}
                          className={`coup-btn palce-order-btn btn-block ${buttonLoader ? "loading-btn" : ""
                            }`}
                        >
                          {buttonLoader ? "Processing..." : "Apply"}
                        </Button>
                        <br />
                        Rs. {discountAmount}
                      </li>
                    </ul>
                    {/* <p style={{ textAlign: "center" }}>
                      Choose available coupons <a href="#">Click here</a>
                    </p> */}
                  </div>
                  <hr />
                  <br />
                  <div className="your-order-bottom">
                    <ul>
                      <li className="your-order-shipping">Tax</li>
                      <li>
                        Rs.<TaxTotal></TaxTotal>
                      </li>
                    </ul>
                  </div>
                  <br />
                  <div className="your-order-bottom">
                    <ul>
                      <li className="your-order-shipping">Shipping</li>
                      <li>
                        {deliveryCharges == 0
                          ? "Free"
                          : `Rs. ${deliveryCharges}`}
                      </li>
                    </ul>
                  </div>
                  <br />
                  <div className="your-order-total">
                    <ul>
                      <li className="order-total">Total</li>
                      <li>
                        Rs. <TotalAmount></TotalAmount>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Row>
                <Col>
                  <br />
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      id={`terms`}
                      type="checkbox"
                      name="terms"
                      value="1"
                      onChange={(e) => {
                        setTerms(e.currentTarget.checked)
                      }}
                      label="To proceed with the order, please accept the terms and conditions."
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="acc-btn-container">
                <Button
                  type="button"
                  variant="primary"
                  onClick={completeOrder}
                  className={`palce-order-btn btn-block ${buttonLoader ? "loading-btn" : ""
                    }`}
                >
                  {buttonLoader ? "Processing..." : "Place order"}
                </Button>
              </div>
            </Col>
          </Row>
        ) : props.cartitems && props.cartitems.length <= 0 && isOrderPlaced ? (
          <Row className="justify-content-md-center">
            <Col sm={6} className="cart-notify-container">
              <h4>Warning!</h4>
              <img src={WarningImage} alt="Shipping image"></img>
              <h5>
                Your order has been placed. Please reinitiate the payment from
                your order, as you have cancelled the payment process.
              </h5>
            </Col>
          </Row>
        ) : (
          <Row className="justify-content-md-center">
            <Col sm={6} className="cart-notify-container">
              <h4>Warning!</h4>
              <img src={EmptyCartImage} alt="Shipping image"></img>
              <h5>Your cart is empty</h5>
            </Col>
          </Row>
        )}
        <Modal
          show={showOtpVerificationModal}
          backdrop="static"
          keyboard={false}
          onHide={handleCloseOTPModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              To complete a order verify your mobile number
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={verifyOtpForm}>
            <Modal.Body>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Mobile Number"
                    name="mobile"
                    value={newAddressForm.mobile}
                    onChange={handleOtpForm}
                    required
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>OTP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="OTP"
                    name="otp"
                    value={newAddressForm.otp}
                    onChange={handleOtpForm}
                    required
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <p style={{ marginLeft: "10px" }}>
                  OTP Not received yet ?{" "}
                  <a href="javascript:void(0)" onClick={handleResendOtp}>
                    Resend otp
                  </a>
                </p>
              </Form.Row>
            </Modal.Body>
            <Modal.Footer className="otp-popup-footer">
              <Button
                type="button"
                variant="secondary"
                onClick={handleCloseOTPModal}
                className={`global-cancel-btn`}
              >
                Close
              </Button>
              <Button type="submit" className={`global-submit-btn`}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <ToastContainer />
      </Container>
    </>
  );
}

Checkout.propTypes = {
  cartitems: PropTypes.array.isRequired,
  loadCartItems: PropTypes.func.isRequired,
  emptyCartItems: PropTypes.func.isRequired,
  userAddresses: PropTypes.array.isRequired,
  loadUserAddresses: PropTypes.func.isRequired,
  addNewAddress: PropTypes.func.isRequired,
  deleteUserAddresses: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    cartitems: state.cartitems,
    userAddresses: state.userAddresses,
  };
}

const mapDispatchToProps = {
  loadCartItems: cartAction.loadCartItems,
  emptyCartItems: cartAction.emptyCartItems,
  loadUserAddresses: addressAction.loadUserAddresses,
  addNewAddress: addressAction.addNewAddress,
  deleteUserAddresses: addressAction.deleteUserAddresses,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
