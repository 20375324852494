import * as types from "./actionTypes";
import AuthService from "../../services/AuthService";

const authAPI = new AuthService();

export function loadLoggedinUserSuccess(loggedinUser) {
  return { type: types.LOAD_LOGINUSER_SUCCESS, loggedinUser };
}

export function getLoggedinUserSuccess(loggedinUser) {
  return { type: types.SEND_LOGINUSER_SUCCESS, loggedinUser };
}

export function logoutUserSuccess(userId) {
  return { type: types.LOGOUT_LOGINUSER_SUCCESS, userId };
}

export function loginUser(getData) {
  return function (dispatch) {
    return authAPI
      .login(getData)
      .then((loggedinUser) => {
        dispatch(loadLoggedinUserSuccess(loggedinUser));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getLoginUser() {
  let userId = 0;
  return function (dispatch) {
    return authAPI
      .getLoginDetails()
      .then((response) => {
        return dispatch(getLoggedinUserSuccess(response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function logoutCurrentUser() {
  let userId = 0;
  return function (dispatch) {
    return authAPI
      .logoutUser()
      .then(() => {
        return dispatch(logoutUserSuccess(userId));
      })
      .catch((error) => {
        throw error;
      });
  };
}
