import React, { useState, useEffect } from "react";
import { Modal, Button, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as cartAction from "../redux/actions/cartAction";
import Environment from "../shared/Environment";
import "../shared/styles/style.css";
import "./cartModal.css";

function CartModal(props) {
  const handleCloseCartModal = () => props.setShowCartModalP(false);
  const [imagePath, setImagePath] = useState(Environment.image_url);

  useEffect(() => {
    props
      .loadCartItems()
      .then((result) => {})
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const decreaseQuantity = (getCartItem) => {
    console.log(getCartItem);
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) - 1;
    props
      .decreaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        // setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const increaseQuantity = (getCartItem) => {
    let thisQuantity = getCartItem.quantity;
    let enteredQuantity = parseInt(thisQuantity) + 1;
    props
      .increaseCartItemQuantity(getCartItem, enteredQuantity)
      .then((result) => {
        // setEnteredQuantity(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function removeFromCart(getItem) {
    props
      .removeItemFromCart(getItem)
      .then((result) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  function gotoCheckoutPage() {
    props.setShowCartModalP(false);
    props.history.push(`/checkout`);
  }

  return (
    <Modal
      show={props.showCartModalP}
      onHide={handleCloseCartModal}
      className={
        props.fixedHeader
          ? "come-from-modal come-from-modal-top right"
          : "come-from-modal right"
      }
      scrollable={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>My Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {props.cartitems && props.cartitems.length > 0 ? (
            props.cartitems.map((item, index) => {
              return (
                <>
                  <div className="product-details-order" key={index}>
                    <div className="img-container">
                      <img
                        src={`${imagePath}${item.image_1}`}
                        className="product-image-order-page"
                      ></img>
                      <span onClick={() => removeFromCart(item)}>
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </div>
                    <div>
                      <Link to={`/product/${item.id}`}>
                        <small>{item.product_name}</small>
                      </Link>
                      <br></br>
                      <small>
                        {item.net_weight} {item.unit_type} x {item.quantity}
                      </small>
                      {/* <br></br> */}
                      {/* <div className="cartItem plus-minus-input">
                        <div className="input-group-button">
                          <button
                            type="button"
                            className="button hollow circle"
                            onClick={() => decreaseQuantity(item)}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                        </div>
                        <FormControl
                          id="inlineFormInputGroup"
                          placeholder=""
                          value={item.quantity}
                          className="cart-change-list-product-input"
                          style={{ width: "15%" }}
                        />
                        <div className="input-group-button">
                          <button
                            type="button"
                            className="button hollow circle"
                            onClick={() => increaseQuantity(item)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      </div> */}
                      <br></br>
                      <small>
                        Rs : ₹ {parseInt(item.quantity) * item.selling_price}
                      </small>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="no-items-cart-mobile">
              <span>No items in cart</span>
            </div>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className="model-text">
          Sub Total : {""} ₹ {""}
          {props.cartitems.reduce(
            (sum, i) => (sum += parseInt(i.subTotal)),
            0
          )}{" "}
        </div>
        <Button
          type="button"
          className="proceed-to-checkout"
          onClick={gotoCheckoutPage}
          disabled={
            props.cartitems && props.cartitems.length > 0 ? false : true
          }
        >
          Proceed to Checkout
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CartModal.propTypes = {
  cartitems: PropTypes.array.isRequired,
  loadCartItems: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    cartitems: state.cartitems,
  };
}

const mapDispatchToProps = {
  loadCartItems: cartAction.loadCartItems,
  removeItemFromCart: cartAction.removeItemFromCart,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CartModal));
