import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeCarousel from "./carousel";
import ProductsCarousel from "./productsCarousel";
import BestSellingProducts from "./bestSellingProducts";
import ComboList from "./comboList";

import FeaturedCategories from "./categoriesFeatured";
import StaticArea from "./staticArea";
import StaticAreaOrganic from "./staticAreaOrganic";
import TestiMonial from "./testiMonial";
import AboutFormer from "./aboutProductStatic";
import Certificate from "./certificates";
import AppBanner from "./appBanner";
import BannerImage from "./bannerImage";
function Home(props) {
  return (
    <>
      <HomeCarousel></HomeCarousel>
      <FeaturedCategories></FeaturedCategories>
      <StaticAreaOrganic></StaticAreaOrganic>
      <ComboList></ComboList>
      <StaticArea></StaticArea>
      <AboutFormer></AboutFormer>
      <BestSellingProducts></BestSellingProducts>
      <Certificate></Certificate>
      <ProductsCarousel></ProductsCarousel>
      <AppBanner></AppBanner>
      <TestiMonial></TestiMonial>
      <br />
      <br />
    </>
  );
}

export default Home;
