import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as loginAction from "../../../redux/actions/loginAction";
import "./index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

function Login(props) {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [buttonLoader, setButtonLoader] = useState(false);

  function handleFormInput(e) {
    const { name, value } = e.target;
    setLoginData((loginData) => ({
      ...loginData,
      [name]: value,
    }));
  }

  function userLogin(e) {
    e.preventDefault();
    setButtonLoader(true);
    props
      .loginUser(loginData)
      .then((data) => {
        props.history.push("/");
      })
      .catch((err) => {
        setButtonLoader(false);
        toast.error(err.data.error[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login - Five Element "Food from Heaven"</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/user/login" />
        <meta
          name="keywords"
          content="Login - Five Element 'Food from Heaven'"
        />

        <meta
          name="description"
          content="Login - Five Element 'Food from Heaven'"
        />
      </Helmet>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center">
          <Col sm={6}>
            <Card className="login-content">
              <Card.Header className="login-header">Login</Card.Header>
              <Card.Body>
                <Form onSubmit={userLogin}>
                  <Form.Group controlId="formBasicEmail">
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      required
                      name="email"
                      value={loginData.email}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    {/* <Form.Label>Password</Form.Label> */}
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      required
                      name="password"
                      value={loginData.password}
                      onChange={handleFormInput}
                    />
                  </Form.Group>
                  <div className="button-box">
                    <div className="login-toggle-btn">
                      <Button
                        className={`global-submit-btn ${buttonLoader ? "loading-btn" : ""
                          }`}
                        type="submit"
                      >
                        {buttonLoader ? "Processing..." : "Submit"}
                      </Button>
                      <Link
                        to="/user/forgot-password"
                        className="forgotpass-link"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="not-register-link">
                    <p>
                      New user ? <Link to="/user/register">Click here</Link> to
                      register
                    </p>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

Login.propTypes = {
  loggedinUser: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    loggedinUser: state.loggedinUser,
  };
}

const mapDispatchToProps = {
  loginUser: loginAction.loginUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
