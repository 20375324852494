import * as types from "../actions/actionTypes";

export default function loginReducer(state = {}, action) {
  switch (action.type) {
    case types.LOAD_LOGINUSER_SUCCESS:
      return action.loggedinUser;
    case types.SEND_LOGINUSER_SUCCESS:
      return action.loggedinUser;
    case types.LOGOUT_LOGINUSER_SUCCESS:
      return {};
    default:
      return state;
  }
}
