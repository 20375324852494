import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import { Carousel } from "react-responsive-carousel";
import "./staticArea.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Card, Button, Container, Row, Col } from "react-bootstrap";
function TestiMonial(props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
        className="carousel"
      >
        <div className="slide">
          <img src={"assets/testimonial.png"} />
          <div className="myCarousel">
            <h3>Sai Prabhu N </h3>
            <h4>Alandur</h4>
            <p>
              I was recently in my hometown, where my grandmother had taught me a
              millet-based recipe. I looked around the store for millet but was
              disappointed. Later, I discovered the Five Element website and was
              able to obtain millet. You'll get all of the native grain varieties
              at Five Element, so go for it.
            </p>
          </div>
          <br />
        </div>

        <div className="slide">
          <img src={"assets/testimonial.png"} />
          <div className="myCarousel">
            <h3>Mrs. Lakshiya </h3>
            <h4>Anna nagar</h4>
            <p>
              I was quite pleased with the Five Element team's prompt delivery and
              customer service response. They were very helpful in locating the
              correct objects. (to add about pandemic)
            </p>
          </div>
          <br />
        </div>

        <div className="slide">
          <img src={"assets/testimonial.png"} />
          <div className="myCarousel">
            <h3>Mr. Dinesh</h3>
            <h4>Nerkundram </h4>
            <p>
              I live with my parents, who are both over 70, and I am very specific
              about what they eat. When my parents grew older, I realised how
              necessary it was to provide them with organic food in order to keep
              them safe. I tried at a nearby market, but the availability of
              supplies was inconsistent, and the food quality was inconsistent at
              times. Then I discovered five elements. Even throughout the
              pandemic, the consistency and availability of supplies remained
              constant over the year.
            </p>
          </div>
          <br />
        </div>

        <div className="slide">
          <img src={"assets/testimonial.png"} />
          <div className="myCarousel">
            <h3>Mrs. Jannani </h3>
            <h4>Ashok nagar</h4>
            <p>
              Dals are a part of every meal in my household, which is
              characteristic of the south Indian culture. My quest for dals
              brought me to the mud processed toor dal, which drew me in first
              because of its name and then because of its flavour. Mud processed
              toor dal brings flavour to sambar, and it had been a long time since
              I had tried a new variety.
            </p>
          </div>
        </div>
        <div className="slide">
          <img src={"assets/testimonial.png"} />
          <div className="myCarousel">
            <h3>DR. Rajagopalan </h3>
            <h4>Anna nagar</h4>
            <p>
              I am a chest and TB specialist. We advise people to use the right
              oil in their everyday cooking, but discovering pure oil has always
              been a challenge. Fortunately, I tried the oil from Five Element,
              and the consistency convinced me to do it again, and I am still
              pleased with the results. It is something I recommend to my patients
              and peers.
            </p>
          </div>
          <br />
        </div>
      </Carousel>
      <Container>
        {/* <Row className="mt-70">
          <Col sm={12} >
            <div id="curator-feed-new-feed-layout">
              <a href="https://curator.io" target="_blank" className="crt-logo crt-tag">Powered by Curator.io</a></div>
          </Col>
        </Row> */}

        <h5 className="header mt-70" style={{ textAlign: 'center' }}>Media</h5>
        <div className="text-center">
          <img
            src={"assets/border-line.png"}
            className="bottom-border-line"
          />
        </div>

        <Row>
          <Col sm={12}>
            {/* <div className="services halfbg ">
              <a className="play-button vid" href="https://youtu.be/8u5nvY35rL4">
                <div className="background bg-img valign" data-overlay-dark="7">
                  <svg className="circle-fill">
                    <circle cx="43" cy="43" r="39" stroke="#fff" stroke-width=".5"></circle>
                  </svg>
                  <svg className="circle-track">
                    <circle cx="43" cy="43" r="39" stroke="none" stroke-width="1" fill="none"></circle>
                  </svg>
                  <span className="polygon">
                    <i className="pe-7s-play"></i>
                  </span>
                </div>
              </a>
            </div> */}

            <div className="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/gmzDt_kg7XM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Col>
        </Row>
        <h5 className="header mt-70" style={{ textAlign: 'center' }}>Our farmers</h5>
        <div className="text-center">
          <img
            src={"assets/border-line.png"}
            className="bottom-border-line"
          />
        </div>
        <Row>
          <Col sm={6} style={{ backgroundColor: "#c4e6b8", padding: "28px" }}>
            <p className="content-text mb-20 mt-10" style={{ color: "#5d5b64" }}>
              We at Five Element chose to be the backbone of our farmers by refusing to buy from them instead standing beside them and selling alongside them. The "Back To Roots" movement began last year (2021) with the goal of developing a relationship with framers rather than just buying from them, educating them on advanced technology, and to help them market their organic produce.
            </p>
            <a
              href={`/know-our-former`}
              style={{ color: "#5d5b64" }}
              onClick={() => setExpanded(false)}
            >
              <b>Read More</b>
            </a>
            <br /><br />
          </Col>
          <Col className="mt-26 former-about">
          </Col>
        </Row>
      </Container >
    </>
  );
}

export default TestiMonial;
