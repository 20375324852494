import React, { useState, useEffect } from "react";
import { Toast } from "react-bootstrap";
import "./index.css";

function ToastCom(props) {
  useEffect(() => {}, [props]);

  return (
    <Toast
      onClose={() => props.setShowToast(false)}
      show={props.showToast}
      delay={5000}
      autohide
      style={{
        position: "absolute",
        top: 130,
        right: 15,
      }}
      className={props.toastType == "error" ? "errorToast" : "successToast"}
    >
      <Toast.Header
        className={props.toastType == "error" ? "errorToast" : "successToast"}
      >
        <strong className="mr-auto">
          {props.toastType == "error" ? "Error" : "Success"}
        </strong>
      </Toast.Header>
      <Toast.Body>
        <ul>
          {props.toastMessage && props.toastMessage
            ? props.toastMessage.map((msg, index) => {
                return <li key={`toast_msg_${index}`}>{msg}</li>;
              })
            : ""}
        </ul>
      </Toast.Body>
    </Toast>
  );
}

export default ToastCom;
