import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Dropdown,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingBag,
  faChevronDown,
  faSearch,
  faLink,
  faMapMarker,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  fab,
  faTwitterSquare,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link, withRouter } from "react-router-dom";
import "../shared/styles/style.css";
import CategoriesService from "../services/CategoriesService";
import AuthService from "../services/AuthService";
import ProductsServices from "../services/ProductsServices";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as cartAction from "../redux/actions/cartAction";
import * as loginAction from "../redux/actions/loginAction";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import BrandLogo from "./logo.png";
import BrandLogoMenu from "./banner-menu.png";
import CartModal from "./cartModal";
import PopupCity from "../components/home/popupCity";
import PaymentImage from "./payment.jpg";
import MobileMenu from "../components/mobilemenu/index";

function WithoutAuth(props) {
  const { children } = props;
  const categoryApiCall = new CategoriesService();
  const authApiCall = new AuthService();
  const productApiCall = new ProductsServices();

  const [fixedHeader, setFixedHeader] = useState(false);
  const [allCategoriesmenu, setAllCategoriesmenu] = useState([]);
  const [isLoggedin, isSetLoggedin] = useState(false);
  const [selectedProductSearch, setSelectedProductSearch] = useState([]);
  const [isLoadingProductSearch, setIsLoadingProductSearch] = useState(false);
  const [productsOptions, setProductsOptions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [showCategoriesDropdown, setShowCategoriesDropdown] = useState(false);
  const [showCartModalP, setShowCartModalP] = useState(false);
  const [loadcity, setLoadcity] = useState(0);
  const [Menuhide, setMenuhide] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollApp);
    props
      .loadCartItems()
      .then((result) => { })
      .catch((err) => {
        console.log(err);
      });

    categoryApiCall
      .getAllMainCategories()
      .then((res) => {
        setAllCategoriesmenu(res);
      })
      .catch((err) => {
        console.log(err);
      });

    props
      .getLoginUser()
      .then((result) => { })
      .catch((err) => {
        console.log(err);
      });
    window.addEventListener("resize", handleResize);
  }, []);

  function logoutUser() {
    props
      .logoutCurrentUser()
      .then((result) => {
        props.history.push("/user/login");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClickCity(city) {
    localStorage.setItem("currentCityid", city);
    window.location.reload();
  }

  function handleScrollApp() {
    if (window.scrollY > 120) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  }

  function handleResize() {
    if (window.innerWidth > 991) {
      setMenuhide(true);
    } else {
      setMenuhide(false);
    }
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <FontAwesomeIcon icon={faChevronDown} className="account-chevron" />
    </a>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  function handleProductSearch(getItem) {
    setSelectedProductSearch(getItem);
    if (getItem.length > 0) {
      props.history.push(`/product/${getItem[0].product_code}`);
    }
  }

  function handleInputChangeProductSearch(getText) {
    setIsLoadingProductSearch(true);

    productApiCall
      .getFilteredProducts(getText)
      .then((res) => {
        setProductsOptions(res);
        setIsLoadingProductSearch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const showDropdown = (e) => {
    setShowCategoriesDropdown(!showCategoriesDropdown);
  };
  const hideDropdown = (e) => {
    setShowCategoriesDropdown(false);
  };

  function openCartModal() {
    setExpanded(false);
    setShowCartModalP(true);
  }

  return (
    <>
      <div className="top-bar">
        <div className="left">S2 Organic Foods India</div>
        {/* <div className="map-icon pointer">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="fa fa-map-marker-alt"
          />
        </div> */}
        {/* <PopupCity onPressFun={handleClickCity} /> */}
        <div className="right settings-option-menu">
          <Dropdown>
            <Dropdown.Toggle
              as={CustomToggle}
              id="dropdown-custom-components settings-dropdown"
            >
              My Account
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {props.loggedinUser && props.loggedinUser.id ? (
                <>
                  <Dropdown.Item as={Link} to="/my-orders">
                    My Orders
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/manage-address">
                    Manage Address
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/change-password">
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
                </>
              ) : (
                <>
                  <Dropdown.Item as={Link} to="/user/register">
                    Register
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/user/login">
                    Login
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Navbar
        collapseOnSelect
        expand="lg"
        // style={{
        //   backgroundImage: "url(" + "assets/b2.png" + ")",
        // }}
        expanded={expanded}
        className={
          fixedHeader
            ? "menu_fixed animated fadeInDown theme-navbar"
            : "animated theme-navbar"
        }
      >
        <Navbar.Brand as={Link} to="/" className="mobile-navbrand">
          <img src={BrandLogo} className="theme-logo" />
        </Navbar.Brand>
        <Nav.Link
          as={Link}
          to="#"
          className="cart-container-mobile"
          onClick={openCartModal}
        >
          <span>
            <FontAwesomeIcon icon={faShoppingBag} className="shop-bag-icon" />
            <sup className="shop-count-container">
              <span className="badge badge-success shop-count-badge">
                {props.cartitems ? props.cartitems.length : 0}
              </span>
            </sup>
          </span>
        </Nav.Link>
        <MobileMenu />

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
          className="mobile-navbar-toggler"
        />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={
            fixedHeader ? "fixed-nav-collapse" : "non-fixed-nav-collapse"
          }
        >
          <Nav className="mr-auto navbar-items">
            <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/our-story"
              onClick={() => setExpanded(false)}
            >
              Our Story
            </Nav.Link>
            <NavDropdown
              title="Shop"
              id="collasible-nav-dropdown"
              className="products-dropdown-menu"
              show={showCategoriesDropdown}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
            >
              <NavDropdown.Item href="#" className="desktop-megamenu">
                <Container>
                  <Col sm={3}>
                    <h4 className="title">Category</h4>
                    <ul>
                      {allCategoriesmenu.length > 0
                        ? allCategoriesmenu.map((cat) => {
                          return (
                            <li key={`catmenu_${cat.id}`}>
                              <Link
                                as={Link}
                                to={`/products/${cat.id}`}
                                onClick={() => setExpanded(false)}
                              >
                                {cat.category_name}
                              </Link>
                            </li>
                          );
                        })
                        : ""}
                    </ul>
                  </Col>
                  <Col sm={9}>
                    <Container>
                      <Col sm={6}>
                        <h4 className="title">Featured Products</h4>
                        <ul>
                          <li>
                            <Link
                              as={Link}
                              to="/product/organic-ambe-mohar-boiled"
                              onClick={() => setExpanded(false)}
                            >
                              Ambe Mohar
                            </Link>
                          </li>
                          <li>
                            <Link
                              as={Link}
                              to="/product/organic-navara-boiled"
                              onClick={() => setExpanded(false)}
                            >
                              Navara
                            </Link>
                          </li>
                          <li>
                            <Link
                              as={Link}
                              to="/product/organic-black-wheat"
                              onClick={() => setExpanded(false)}
                            >
                              Black Wheat
                            </Link>
                          </li>
                          <li>
                            <Link
                              as={Link}
                              to="/product/organic-toor-dal-mud-processed"
                              onClick={() => setExpanded(false)}
                            >
                              Toordhal Mud Proccessed
                            </Link>
                          </li>
                        </ul>
                      </Col>
                      <Col sm={6}>
                        <h4 className="title">Best Selling</h4>
                        <ul>
                          <li>
                            <Link
                              as={Link}
                              to="/product/organic-thooyamalli-boiled"
                              onClick={() => setExpanded(false)}
                            >
                              Thooyamalli
                            </Link>
                          </li>
                          <li>
                            <Link
                              as={Link}
                              to="/product/organic-moongil-rice-boiled"
                              onClick={() => setExpanded(false)}
                            >
                              Moong Dal
                            </Link>
                          </li>
                          <li>
                            <Link
                              as={Link}
                              to="/product/organic-jaggery-powder"
                              onClick={() => setExpanded(false)}
                            >
                              Jaggery powder
                            </Link>
                          </li>
                          <li>
                            <Link
                              as={Link}
                              to="/product/organic-cold-pressed-sesame-oil"
                              onClick={() => setExpanded(false)}
                            >
                              Sesame Oil
                            </Link>
                          </li>
                        </ul>
                      </Col>
                      <Col style={{ marginTop: "20px" }}>
                        <img src={BrandLogoMenu} className="menu-logo" />
                      </Col>
                    </Container>
                  </Col>
                </Container>
              </NavDropdown.Item>
              {allCategoriesmenu.length > 0
                ? allCategoriesmenu.map((cat) => {
                  return (
                    <NavDropdown.Item
                      as={Link}
                      to={`/products/${cat.id}`}
                      key={`catmenu_${cat.id}`}
                      className="mobile-menu"
                      onClick={() => setExpanded(false)}
                    >
                      {cat.category_name}
                    </NavDropdown.Item>
                  );
                })
                : ""}
            </NavDropdown>

            <Nav.Link as={Link} to="/blog" onClick={() => setExpanded(false)}>
              Blog
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact-us"
              onClick={() => setExpanded(false)}
              className="main-nav-con"
            >
              Contact us
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/my-orders"
              onClick={() => setExpanded(false)}
            >
              <span className="mobile-nav-big">My Orders</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/user/login"
              onClick={() => setExpanded(false)}
            >
              <span className="mobile-nav-big">Login </span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/user/register"
              onClick={() => setExpanded(false)}
            >
              <span className="mobile-nav-samll">Register</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/customer-feedback"
              onClick={() => setExpanded(false)}
            >
              <span className="mobile-nav-samll">Feedback </span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/privacy-policy"
              onClick={() => setExpanded(false)}
            >
              <span className="mobile-nav-samll">Privacy Policy</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/order-cancellation-policy"
              onClick={() => setExpanded(false)}
            >
              <span className="mobile-nav-samll">Return & Refunds</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact-us"
              onClick={() => setExpanded(false)}
            >
              <span className="mobile-nav-samll">Contact us</span>
            </Nav.Link>
          </Nav>
          <Nav>
            {/* <Nav.Link eventKey={2} href="#" className="call-us-header">
              <span>
                Call us <br></br> (044)- 42466088
              </span>
            </Nav.Link> */}
            <Nav.Link eventKey={2} href="#" className="search-container">
              <InputGroup>
                {/* <FormControl
                  id="inlineFormInputGroup"
                  placeholder="Search entire store here"
                  className="searchbox-header"
                /> */}
                <AsyncTypeahead
                  // filterBy={true}
                  isLoading={isLoadingProductSearch}
                  minLength={3}
                  id="basic-typeahead-single"
                  labelKey="product_name"
                  onChange={(selectedProductSearch) =>
                    handleProductSearch(selectedProductSearch)
                  }
                  options={productsOptions.length > 0 ? productsOptions : []}
                  placeholder="Choose a state..."
                  selected={selectedProductSearch}
                  className="searchbox-header"
                  placeholder="Search entire store here"
                  onSearch={(input) => handleInputChangeProductSearch(input)}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text className="searchbox-header-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/cart"
              className="cart-container cart-container-desktop"
              onClick={() => setExpanded(false)}
            >
              <span>
                <FontAwesomeIcon
                  icon={faShoppingBag}
                  className="shop-bag-icon"
                />
                <sup className="shop-count-container">
                  <span className="badge badge-success shop-count-badge">
                    {props.cartitems ? props.cartitems.length : 0}
                  </span>
                </sup>
              </span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>{children}</div>
      <Container fluid className="footer">
        <Row>
          <Col sm={3}>
            <div className="single-wedge">
              <div className="footer-logo">
                <a href="#">
                  <img
                    src={BrandLogo}
                    className="theme-logo"
                    alt="footer logo"
                  />
                </a>
              </div>
              <div className="about-footer">
                <p className="text-info-fo">
                  {/* We are a team of designers and developers that create high */}
                  {/* quality HTML template */}
                </p>
              </div>
            </div>
          </Col>
          <Col sm={3} xs={6}>
            <div className="single-wedge">
              <h4 className="footer-herading">Information</h4>
              <div className="footer-links">
                <ul>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/know-our-former">Know our Former</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/customer-feedback">Feedback</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm={3} xs={6}>
            <div className="single-wedge">
              <h4 className="footer-herading">Custom Links</h4>
              <div className="footer-links">
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/order-cancellation-policy">
                      Return & Refunds
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/register">Register</Link>
                  </li>
                  <li>
                    <Link to="/user/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/my-orders">My Orders</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>

          <Col sm={3}>
            <div className="single-wedge">
              <h4 className="footer-herading">Follow us on</h4>
              <div className="social-info">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/Fiveelementfoodfromheaven"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/fiveelementfoodfromheaven"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/fiveelement_ffh"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faTwitterSquare} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UC6fNAvX4DzDngvSfKGQY9Jg"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="footer footer-bottom">
        <Row>
          <Col sm={12}>
            <p className="copy-text footer-cont ">
              Copyright © 2022 All Rights Reserved S2 Organic Foods India.
            </p>
          </Col>
          {/* <Col sm={6} className="payment-img-align">
            <img className="payment-img" src={PaymentImage} alt="" />
          </Col> */}
        </Row>
      </Container>
      <CartModal
        showCartModalP={showCartModalP}
        setShowCartModalP={setShowCartModalP}
        fixedHeader={fixedHeader}
      ></CartModal>
    </>
  );
}

WithoutAuth.propTypes = {
  cartitems: PropTypes.array.isRequired,
  loadCartItems: PropTypes.func.isRequired,
  loggedinUser: PropTypes.object.isRequired,
  getLoginUser: PropTypes.func.isRequired,
  logoutCurrentUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    cartitems: state.cartitems,
    loggedinUser: state.loggedinUser,
  };
}

const mapDispatchToProps = {
  loadCartItems: cartAction.loadCartItems,
  getLoginUser: loginAction.getLoginUser,
  logoutCurrentUser: loginAction.logoutCurrentUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WithoutAuth));
