import * as types from "../actions/actionTypes";

export default function addressReducer(state = [], action) {
  switch (action.type) {
    case types.LOAD_ADDRESS_SUCCESS:
      return action.userAddresses;
    case types.ADD_ADDRESS_SUCCESS:
      return [{ ...action.userAddress }, ...state];
    case types.UPDATE_ADDRESS_SUCCESS:
      return state.map((address) =>
        address.id == action.userAddress.id ? action.userAddress : address
      );
    case types.REMOVE_ADDRESS_SUCCESS:
      return state.filter((address) => address.id !== action.addressId);
    default:
      return state;
  }
}
