import { combineReducers } from "redux";
import cartitems from "./cartReducer";
import userAddresses from "./addressReducer";
import loggedinUser from "./loginReducer";

const rootReducer = combineReducers({
  cartitems,
  userAddresses,
  loggedinUser,
});

export default rootReducer;
