import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import WithoutAuth from "./layouts/withoutAuth";
import Home from "./components/home";
import Products from "./components/listProducts";
import Product from "./components/product";
import Cart from "./components/cart";
import Checkout from "./components/checkout";
import MyOrders from "./components/account/orders";
import OrderDetail from "./components/account/orders/orderDetail";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import VerifyOtp from "./components/auth/verifyUser";
import OrderNotify from "./components/checkout/orderSuccess";
import Blog from "./components/blog";
import BlogDetails from "./components/blog/blogDetails";
import CategoryBlog from "./components/blog/categoryBlog";
import ContactUs from "./components/contactus";
import PrivacyPolicy from "./components/privacy-policy";
import About from "./components/about-us";
import Terms from "./components/terms";

import CancellationPolicy from "./components/cancellation";
import FeedBack from "./components/feedback";
import PaymentSuccess from "./components/account/orders/paymentSuccess";
import ChangePassword from "./components/account/changePassword";
import ManageAddress from "./components/account/manageAddress";
import ForgotPassword from "./components/auth/forgotPassword";

import ScrollToTop from "./components/common/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import Formers from "./components/farmers";
import { Helmet } from "react-helmet";



function App() {
  const WithoutAuthContainer = () => {
    return (
      <WithoutAuth>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/products/:id" component={Products}></Route>
        <Route exact path="/product/:product_name" component={Product}></Route>
        <Route path="/cart" component={Cart}></Route>
        <Route path="/checkout" component={Checkout}></Route>
        <Route path="/my-orders" component={MyOrders}></Route>
        <Route path="/order-detail/:id" component={OrderDetail}></Route>
        <Route path="/change-password" component={ChangePassword}></Route>
        <Route path="/user/login" component={Login}></Route>
        <Route path="/user/register" component={Register}></Route>
        <Route path="/user/verify/:id" component={VerifyOtp}></Route>
        <Route path="/order/notify/:id" component={OrderNotify}></Route>
        <Route exact path="/blog" component={Blog}></Route>
        <Route exact path="/blog/:id" component={CategoryBlog}></Route>
        <Route path="/blogdetails/:id" component={BlogDetails}></Route>
        <Route exact path="/contact-us" component={ContactUs}></Route>
        <Route exact path="/know-our-former" component={Formers}></Route>
        <Route exact path="/our-story" component={About}></Route>
        <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
        <Route exact path="/customer-feedback" component={FeedBack}></Route>
        <Route exact path="/terms-conditions" component={Terms}></Route>
        <Route
          exact
          path="/order/payment-success/:id"
          component={PaymentSuccess}
        ></Route>
        <Route
          exact
          path="/order-cancellation-policy"
          component={CancellationPolicy}
        ></Route>
        <Route exact path="/manage-address" component={ManageAddress}></Route>
        <Route path="/user/forgot-password" component={ForgotPassword}></Route>
      </WithoutAuth>
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Five Element Food From Heaven Best Online Organic Store</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/our-story" />
        <meta
          name="keyword"
          content="Five Element Food From Heaven Best Online Organic Store"
        />
        <meta
          name="description"
          content="Five Element Food From Heaven Best Online Organic Store"
        />
      </Helmet>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={WithoutAuthContainer} />
            <Route path="/products/:id" component={WithoutAuthContainer} />
            <Route path="/product/:id" component={WithoutAuthContainer} />
            <Route path="/cart" component={WithoutAuthContainer} />
            <Route path="/checkout" component={WithoutAuthContainer} />
            <Route path="/my-orders" component={WithoutAuthContainer} />
            <Route
              path="/order-detail/:id"
              component={WithoutAuthContainer}
            ></Route>
            <Route path="/change-password" component={WithoutAuthContainer} />
            <Route path="/user/login" component={WithoutAuthContainer}></Route>
            <Route path="/user/register" component={WithoutAuthContainer}></Route>
            <Route
              path="/user/verify/:id"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              path="/order/notify/:id"
              component={WithoutAuthContainer}
            ></Route>
            <Route exact path="/blog" component={WithoutAuthContainer}></Route>
            <Route
              exact
              path="/blog/:id"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              path="/blogdetails/:id"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              exact
              path="/contact-us"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              exact
              path="/know-our-former"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              exact
              path="/our-story"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              exact
              path="/terms-conditions"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              exact
              path="/privacy-policy"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              exact
              path="/customer-feedback"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              exact
              path="/order-cancellation-policy"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              exact
              path="/manage-address"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              exact
              path="/order/payment-success/:id"
              component={WithoutAuthContainer}
            ></Route>
            <Route
              path="/user/forgot-password"
              component={WithoutAuthContainer}
            ></Route>
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
