import React, { useEffect, useState } from "react";
import "./menu-style.css";
import jQuery from "jquery";
import { useHistory } from "react-router-dom";
import CategoriesService from "../../services/CategoriesService";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "react-bootstrap";
import ProductsServices from "../../services/ProductsServices";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const App = (props) => {
  let history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const categoryApiCall = new CategoriesService();
  const productApiCall = new ProductsServices();
  const [allCategoriesmenu, setAllCategoriesmenu] = useState([]);
  const [selectedProductSearch, setSelectedProductSearch] = useState([]);
  const [isLoadingProductSearch, setIsLoadingProductSearch] = useState(false);
  const [productsOptions, setProductsOptions] = useState([]);

  function handleProductSearch(getItem) {
    setSelectedProductSearch(getItem);
    if (getItem.length > 0) {
      history.push(`/product/${getItem[0].id}`);
    }
  }

  function handleInputChangeProductSearch(getText) {
    setIsLoadingProductSearch(true);

    productApiCall
      .getFilteredProducts(getText)
      .then((res) => {
        setProductsOptions(res);
        setIsLoadingProductSearch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    categoryApiCall
      .getAllMainCategories()
      .then((res) => {
        setAllCategoriesmenu(res);
      })
      .catch((err) => {
        console.log(err);
      });

    (function ($) {
      var size;

      // VERIFY WINDOW SIZE
      function windowSize() {
        size = $(document).width();
        if (size >= 991) {
          $("body").removeClass("open-menu");
          $(".hamburger-menu .bar").removeClass("animate");
        }
      }

      // ESC BUTTON ACTION
      $(document).keyup(function (e) {
        if (e.keyCode == 27) {
          $(".bar").removeClass("animate");
          $("body").removeClass("open-menu");
          $(
            "header .desk-menu .menu-container .menu .menu-item-has-children a ul"
          ).each(function (index) {
            $(this).removeClass("open-sub");
          });
        }
      });

      // ANIMATE HAMBURGER MENU
      $(".hamburger-menu").on("click", function () {
        $(".hamburger-menu .bar").toggleClass("animate");
        if ($("body").hasClass("open-menu")) {
          $("body").removeClass("open-menu");
        } else {
          $("body").toggleClass("open-menu");
        }
      });

      $(
        "header .desk-menu .menu-container .menu .menu-item-has-children ul"
      ).each(function (index) {
        $(this).prepend('<li class="back"><a href="#">Back</a></li>');
      });

      // RESPONSIVE MENU NAVIGATION
      $(
        "header .desk-menu .menu-container .menu .menu-item-has-children > a"
      ).on("click", function (e) {
        e.preventDefault();
        if (size <= 991) {
          $(this).next("ul").addClass("open-sub");
        }
      });

      // CLICK FUNCTION BACK MENU RESPONSIVE
      $(
        "header .desk-menu .menu-container .menu .menu-item-has-children ul .back"
      ).on("click", function (e) {
        e.preventDefault();
        $(this).parent("ul").removeClass("open-sub");
      });

      $("body .over-menu").on("click", function () {
        $("body").removeClass("open-menu");
        $(".bar").removeClass("animate");
      });

      $(document).ready(function () {
        windowSize();
      });

      $(window).resize(function () {
        windowSize();
      });
    })(jQuery);
  }, []);

  return (
    <div className="m-container">
      <div className="over-menu"></div>
      <div className="hm-icon">
        <div className="hamburger-menu">
          <div className="bar" />
        </div>
      </div>
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="desk-menu">
                <nav className="box-menu">
                  <div className="menu-container">
                    <div className="menu-header-container">
                      <ul className="menu">
                        <li className="menu-item">
                          <a href="/" onClick={() => setExpanded(false)}>
                            Home
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/our-story"
                            onClick={() => setExpanded(false)}
                          >
                            Our Story
                          </a>
                        </li>
                        <li className="menu-item menu-item-has-children">
                          <a href="#">Shop</a>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-has-children">
                              <a href="#">Category</a>
                              <ul className="sub-menu sc-back">
                                <li className="back">
                                  <a href="#">Back</a>
                                </li>
                                {allCategoriesmenu &&
                                  allCategoriesmenu.length > 0
                                  ? allCategoriesmenu.map((cat) => {
                                    return (
                                      <li
                                        className="menu-item"
                                        key={`catmenu_${cat.id}`}
                                      >
                                        <a
                                          href={`/products/${cat.id}`}
                                          onClick={() => setExpanded(false)}
                                        >
                                          {cat.category_name}
                                        </a>
                                      </li>
                                    );
                                  })
                                  : ""}
                              </ul>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <a href="/product/organic-ambe-mohar-boiled">Featured Products</a>
                              <ul className="sub-menu">
                                <li className="menu-item">
                                  <a
                                    href="/product/organic-ambe-mohar-boiled"
                                    onClick={() => setExpanded(false)}
                                  >
                                    Ambha Mohar
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    href="/product/organic-navara-boiled"
                                    onClick={() => setExpanded(false)}
                                  >
                                    Navara
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    href="/product/organic-black-wheat"
                                    onClick={() => setExpanded(false)}
                                  >
                                    Black Wheat
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    href="/product/organic-toor-dal-mud-processed"
                                    onClick={() => setExpanded(false)}
                                  >
                                    Toordhal Mud Proccessed
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <a href="/product/1">Best Selling</a>
                              <ul className="sub-menu">
                                <li className="menu-item">
                                  <a
                                    href="/product/organic-thooyamalli-boiled"
                                    onClick={() => setExpanded(false)}
                                  >
                                    Thooyamalli
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    href="/product/organic-moongil-rice-boiled"
                                    onClick={() => setExpanded(false)}
                                  >
                                    Moong Dal
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    href="/product/organic-jaggery-powder"
                                    onClick={() => setExpanded(false)}
                                  >
                                    Jaggery powder
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    href="/product/organic-cold-pressed-sesame-oil"
                                    onClick={() => setExpanded(false)}
                                  >
                                    Sesame Oil
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <a href="/blog" onClick={() => setExpanded(false)}>
                            Blog
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/my-orders"
                            onClick={() => setExpanded(false)}
                          >
                            My Orders
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/user/login"
                            onClick={() => setExpanded(false)}
                          >
                            Login
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/user/register"
                            onClick={() => setExpanded(false)}
                          >
                            Register
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/customer-feedback"
                            onClick={() => setExpanded(false)}
                          >
                            Feedback
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/privacy-policy"
                            onClick={() => setExpanded(false)}
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/order-cancellation-policy"
                            onClick={() => setExpanded(false)}
                          >
                            Returns &amp; Refunds
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            href="/contact-us"
                            onClick={() => setExpanded(false)}
                          >
                            Contact Us
                          </a>
                        </li>
                        <li className="menu-item">
                          <a eventKey={2} href="#" className="search-container">
                            <InputGroup>
                              {/* <FormControl
                                    id="inlineFormInputGroup"
                                    placeholder="Search entire store here"
                                    className="searchbox-header"
                                  /> */}
                              <AsyncTypeahead
                                // filterBy={true}
                                isLoading={isLoadingProductSearch}
                                minLength={3}
                                id="basic-typeahead-single"
                                labelKey="product_name"
                                onChange={(selectedProductSearch) =>
                                  handleProductSearch(selectedProductSearch)
                                }
                                options={
                                  productsOptions.length > 0
                                    ? productsOptions
                                    : []
                                }
                                placeholder="Choose a state..."
                                selected={selectedProductSearch}
                                className="searchbox-header"
                                style={{ width: '204px !important' }}
                                placeholder="Search entire store here"
                                onSearch={(input) =>
                                  handleInputChangeProductSearch(input)
                                }
                              />
                              <InputGroup.Prepend>
                                <InputGroup.Text className="searchbox-header-icon">
                                  <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                            </InputGroup>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default App;
