import React, { useState } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import "./aboutProductStatic.css";
import { Link } from "react-router-dom";

function AboutFormer(props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Container className="mt-70">
        <h5 className="header">Our Story</h5>
        <div className="text-center">

          <img
            src={"assets/border-line.png"}
            className="bottom-border-line"
          />
        </div>
        <Row style={{ margin: '0px 0px 0px' }}>
          <Col sm={6} style={{ backgroundColor: '#FFE7C7', padding: '30px' }}>
            <p className="content-text mb-20 mt-10">
              Five Element - "Food from Heaven" is an eco-friendly company on a mission to support local organic farmers in selling their produce and to create a more sustainable environment. we care about the entire ecosystem and not just one stakeholder, organic food cultivation provides a solution to many of today's problems. Amongst the five elements of nature (Air, Water, Fire, Ether, and Earth), the food we consume is the most direct link between us and the Earth. Our ancestors nurtured a connection with nature.
            </p>
            <p className="content-text">
              An organic diet is more than just nutrition; it is an expression of your attitude toward life, as per the vice saying goes, "You are what you eat".
            </p>
            <br />
            <a
              href={`/our-story`}
              style={{ color: "#908e8c" }}
              onClick={() => setExpanded(false)}
            >
              <b>Read More</b>
            </a>
          </Col>
          <Col sm={6} className="former-area">
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default AboutFormer;
