import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faMapMarker,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  fab,
  faTwitterSquare,
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import Environment from "../../shared/Environment";
import AuthService from "../../services/AuthService";
import ToastCom from "../common/toasts";
import "./index.css";
import { Helmet } from "react-helmet";

function CancellationPolicy(props) {
  const authApiCall = new AuthService();
  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState();

  function handleFormInput(e) {
    const { name, value } = e.target;
    setLoginData((loginData) => ({
      ...loginData,
      [name]: value,
    }));
  }

  function userLogin(e) {
    e.preventDefault();
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Return and Refunds - Five Element "Food from Heaven"</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/order-cancellation-policy" />
        <meta
          name="keywords"
          content="Return and Refunds - Five Element 'Food from Heaven'"
        />
        <meta
          name="description"
          content="Return and Refunds - Five Element 'Food from Heaven'"
        />
      </Helmet>
      <div className="breadcrumb-area-contact">
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <div className="breadcrumb-content">
                <h1 className="breadcrumb-hrading">Return & Refunds</h1>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Col className="privacy-area">
        <div className="container">
          <h4 className="header-page">Return</h4>
          <p>
            Our policy lasts 7 days. If 7 days have gone by since your purchase,
            unfortunately, we can’t offer you a refund or exchange.
          </p>
          <p>
            To be eligible for a return, your item must be unused and in the
            same condition that you received it. It must also be in the original
            packaging
          </p>
          <p>
            Several types of goods are exempt from being returned. Perishable
            goods such as fruits, vegetables, tender coconut and milk cannot be
            returned.
          </p>
          <p>
            Additional non-returnable items: <br />
            <p>- Gift cards</p>
            <p>- Downloadable software products</p>
            <p>
              - Some health and personal care items To complete your return, we
              require a receipt or proof of purchase.
            </p>
          </p>
          <p>
            You will be responsible for paying for your own shipping costs for
            returning your item. Shipping costs are non-refundable. If you
            receive a refund, the cost of return shipping will be deducted from
            your refund
          </p>
          <h4 className="header-page">Refunds </h4>
          <p>
            Once your return is received and inspected, we will send you an
            email to notify you that we have received your returned item. We
            will also notify you of the approval or rejection of your refund. If
            you are approved, then your refund will be processed, and a credit
            will automatically be applied to your credit card or original method
            of payment, within a 7-14 working days. Note: Any payment processing
            fees associated with your order will be automatically dedicated from
            the refunded amount during the processing of any/all returns.
          </p>
          <h4 className="header-page">Late or missing refunds </h4>
          <p>
            If you haven’t received a refund yet, first check your bank account
            again. Then contact your credit card company, it may take some time
            before your refund is officially posted. Next contact your bank.
            There is often some processing time before a refund is posted. If
            you’ve done all of this and you still have not received your refund
            yet, please contact us at info@fiveelementorganic.com.
          </p>
          <h4 className="header-page">Sale items </h4>
          <p>
            Only regular priced items may be refunded, unfortunately, sale items
            cannot be refunded.
          </p>
          <h4 className="header-page">Exchanges </h4>
          <p>
            We only replace items if they are defective or damaged. If you need
            to exchange it for the same item, send us an email at
            info@fiveelementorganic.com and we will collect the product.
          </p>
        </div>
      </Col>
    </>
  );
}

export default CancellationPolicy;
