import Environment from "../shared/Environment";

export default class AddressService {
  constructor() {
    this.baseURL = Environment.baseURL;
    this.fetch = this.fetch.bind(this);
    this.fetchWithoutToken = this.fetchWithoutToken.bind(this);
  }

  addNewAddress(getData) {
    return this.fetch(`${this.baseURL}/address/add`, {
      method: "POST",
      body: getData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  sendOtp(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/customer/sendotp`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  resendOtp(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/customer/resendotp`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  pincodeValidation(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/area-code-validate`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  verifyOtp(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/customer/verifyotp`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getMyCityAll() {
    return this.fetch(`${this.baseURL}/citylist`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getUserAddress() {
    return this.fetch(`${this.baseURL}/address`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  deleteUserAddress(getId) {
    return this.fetch(`${this.baseURL}/address`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  updateSelectedUserAddress(getData) {
    return this.fetch(`${this.baseURL}/address/update`, {
      method: "POST",
      body: getData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getAddressDetails() {
    return Promise.resolve();
  }

  getDeliveryPincodes() {
    return this.fetch(`${this.baseURL}/area-codes-list`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getOfferCodeDetails(data) {
    let finalData = JSON.stringify(data);
    return this.fetch(`${this.baseURL}/discount-details`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  completeOrder(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/make-order`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  checkoutOrder(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/order-checkout`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  updateOrderStatus(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/update-payment-status`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getMyOrdereAll() {
    return this.fetch(`${this.baseURL}/myorders`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getMyOrderById(getId) {
    return this.fetch(`${this.baseURL}/myorder/details/${getId}`, {
      method: "GET",
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  retryPayment(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/retry-Payment`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  cancelOrder(getData) {
    let finalData = JSON.stringify(getData);
    return this.fetch(`${this.baseURL}/cancel-order`, {
      method: "POST",
      body: finalData,
    }).then((res) => {
      return Promise.resolve(res);
    });
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("loginToken");
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {};

    headers["Authorization"] = "Bearer " + this.getToken();
    headers["Accept"] = "application/json";
    headers["Access-Control-Allow-Origin"] = "*";
    headers["Content-Type"] = "application/json";

    return fetch(url, {
      headers,
      crossDomain: true,
      ...options,
    })
      .then(this.processResponse)
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.status === 400) {
          throw response;
        } else if (response.status === 403) {
          throw response;
        } else if (response.status === 500) {
          window.location.href = "/InternalServerError";
        }
      });
  }

  fetchWithoutToken(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {};

    headers["Accept"] = "application/json";
    headers["Access-Control-Allow-Origin"] = "*";
    headers["Content-Type"] = "application/json";

    return fetch(url, {
      headers,
      crossDomain: true,
      ...options,
    })
      .then(this.processResponse)
      .then((response) => {
        return response.data;
      })
      .catch((response) => {
        if (response.status === 400) {
          throw response;
        } else if (response.status === 403) {
          throw response;
        } else if (response.status === 500) {
          window.location.href = "/InternalServerError";
        }
      });
  }

  processResponse(response) {
    return new Promise((resolve, reject) => {
      // will resolve or reject depending on status, will pass both "status" and "data" in either case
      let func;
      response.status < 400 ? (func = resolve) : (func = reject);
      if (response.status === 204) {
        response.then((data) =>
          func({ status: response.status, data: { status: "success" } })
        );
      } else if (response.status === 500) {
        window.location.href = "/InternalServerError";
      } else if (response.status === 401) {
        window.location.href = "/Unauthorized";
      } else if (response.status === 502) {
        window.location.href = "/ServerError";
      } else {
        response
          .json()
          .then((data) => func({ status: response.status, data: data }));
      }
    });
  }
}
