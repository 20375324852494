import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import shippingImage from "./static-icons-1.png";
import "./index.css";

function OrderNotify(props) {
  const {
    match: { params },
  } = props;
  return (
    <>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center">
          <Col sm={6} className="order-details">
            <h4>Confirmed!</h4>
            {/* <h5>THANK YOUR FOR YOUR ORDER</h5> */}
            <img src={shippingImage} alt="Shipping image"></img>
            {/* <h5>ESTIMATED DELIVERY</h5> */}
            <h5>Your purchase has been made.</h5>
            <h6>
              Thank you for your purchase , your order no: #00000{params.id}
            </h6>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OrderNotify;
