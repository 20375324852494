import * as types from "./actionTypes";
import AddressService from "../../services/AddressService";

const addressAPI = new AddressService();

export function loadUserAddressesSuccess(userAddresses) {
  return { type: types.LOAD_ADDRESS_SUCCESS, userAddresses };
}

export function newUserAddressSuccess(userAddress) {
  return { type: types.ADD_ADDRESS_SUCCESS, userAddress };
}

export function deleteUserAddressSuccess(addressId) {
  return { type: types.REMOVE_ADDRESS_SUCCESS, addressId };
}

export function getAddressByIdSuccess(addressId) {
  return { type: types.GET_ADDRESS_BY_ID_SUCCESS, addressId };
}

export function updateUserAddressSuccess(userAddress) {
  return { type: types.UPDATE_ADDRESS_SUCCESS, userAddress };
}

export function loadUserAddresses() {
  return function (dispatch) {
    return addressAPI
      .getUserAddress()
      .then((userAddresses) => {
        dispatch(loadUserAddressesSuccess(userAddresses));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addNewAddress(getNewAddress) {
  let finalData = JSON.stringify(getNewAddress);
  return function (dispatch) {
    return addressAPI
      .addNewAddress(finalData)
      .then((response) => {
        let userAddress = getNewAddress;
        userAddress.id = response.address;
        userAddress.user_id = "";
        userAddress.deleted_at = "";
        userAddress.created_at = "";
        userAddress.updated_at = "";
        dispatch(newUserAddressSuccess(userAddress));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteUserAddresses(getId) {
  return function (dispatch) {
    return addressAPI
      .deleteUserAddress(getId)
      .then((response) => {
        dispatch(deleteUserAddressSuccess(getId));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getAddressById(addressId) {
  return function (dispatch) {
    return addressAPI
      .getAddressDetails()
      .then(() => {
        return dispatch(getAddressByIdSuccess(addressId));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateUserAddress(userAddress) {
  let finalData = JSON.stringify(userAddress);
  return function (dispatch) {
    return addressAPI
      .updateSelectedUserAddress(finalData)
      .then((response) => {
        dispatch(updateUserAddressSuccess(userAddress));
      })
      .catch((error) => {
        throw error;
      });
  };
}
