import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faMapMarker,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  fab,
  faTwitterSquare,
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import Environment from "../../shared/Environment";
import AuthService from "../../services/AuthService";
import ToastCom from "../common/toasts";
import "./index.css";
import { Helmet } from "react-helmet";

function FeedBack(props) {
  const authApiCall = new AuthService();
  const [imagePath, setImagePath] = useState(Environment.image_url);
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    mobile: "",
    content: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);

  function handleFormInput(e) {
    const { name, value } = e.target;
    setContactForm((contactForm) => ({
      ...contactForm,
      [name]: value,
    }));
  }

  function submitFeedback(e) {
    e.preventDefault();
    setButtonLoader(true);
    authApiCall
      .submitFeedback(contactForm)
      .then((data) => {
        setShowToast(true);
        setToastType("success");
        setToastMessage(["Your feedback is submitted"]);
        setTimeout(() => {
          setButtonLoader(false);
          setContactForm({
            name: "",
            email: "",
            mobile: "",
            content: "",
          });
        }, 5000);
      })
      .catch((err) => {
        setButtonLoader(false);
        setShowToast(true);
        setToastType("error");
        setToastMessage(err.data.error);
      });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Story - Five Element "Food from Heaven"</title>
        <link rel="canonical" href="https://www.fiveelementorganic.com/customer-feedback" />
        <meta
          name="keywords"
          content="Customer Feedback - Five Element 'Food from Heaven'"
        />

        <meta
          name="description"
          content="Customer Feedback - Five Element 'Food from Heaven'"
        />
      </Helmet>
      <div className="breadcrumb-area-contact">
        <Container className="inner-page-container-main">
          <Row>
            <div className="col-md-12">
              <div className="breadcrumb-content">
                <h1 className="breadcrumb-hrading">Feedback</h1>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Container className="inner-page-container-main">
        <Row className="justify-content-md-center">
          <Col sm={7}>
            <div className="contact-form">
              <div className="contact-title mb-30">
                <h2>Get In Touch</h2>
              </div>
              <form
                className="contact-form-style"
                id="contact-form"
                onSubmit={submitFeedback}
              >
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <input
                      name="name"
                      value={contactForm.name}
                      onChange={handleFormInput}
                      placeholder="Name*"
                      type="text"
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <input
                      name="email"
                      value={contactForm.email}
                      onChange={handleFormInput}
                      placeholder="Email*"
                      type="email"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <input
                      name="mobile"
                      value={contactForm.mobile}
                      onChange={handleFormInput}
                      placeholder="Mobile*"
                      type="text"
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <textarea
                      name="content"
                      value={contactForm.content}
                      onChange={handleFormInput}
                      placeholder="Your Message*"
                      rows="3"
                      required
                    ></textarea>
                    <button
                      className={`global-submit-btn ${buttonLoader ? "loading-btn" : ""
                        }`}
                      type="submit"
                    >
                      {buttonLoader ? "Processing..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
        <ToastCom
          showToast={showToast}
          toastType={toastType}
          toastMessage={toastMessage}
          setShowToast={setShowToast}
        ></ToastCom>
      </Container>
    </>
  );
}

export default FeedBack;
