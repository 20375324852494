import * as types from "../actions/actionTypes";

export default function cartReducer(state = [], action) {
  switch (action.type) {
    case types.LOAD_CART_SUCCESS:
      return action.cartitems;
    case types.ADD_TO_CART_SUCCESS:
      return [{ ...action.cartitem }, ...state];
    case types.UPDATE_ITEM_CART_SUCCESS:
      return state.map((cartitem) =>
        cartitem.id === action.cartitem.id ? action.cartitem : cartitem
      );
    default:
      return state;
  }
}
