export default class CartService {
  constructor() {}

  getAllCartItems() {
    let cartItems = localStorage.getItem("cartItems");
    // return cartItems ? JSON.parse(cartItems) : [];
    return Promise.resolve(cartItems ? JSON.parse(cartItems) : []);
  }

  addNewItemtoCart(getProductDetails, enteredQuantity) {
    let getExistingCartItems = localStorage.getItem("cartItems");
    let getExistingCartItemsArray = getExistingCartItems
      ? JSON.parse(getExistingCartItems)
      : [];
    getProductDetails.quantity = enteredQuantity;
    let subTotalAmount =
      parseInt(enteredQuantity) * getProductDetails.selling_price;
    getProductDetails.subTotal = subTotalAmount.toString();
    getExistingCartItemsArray.push(getProductDetails);
    getExistingCartItemsArray = JSON.stringify(getExistingCartItemsArray);
    localStorage.setItem("cartItems", getExistingCartItemsArray);
    return Promise.resolve(getProductDetails);
  }

  updateQuantityToCartItem(getProductDetails, enteredQuantity) {
    let getExistingCartItems = localStorage.getItem("cartItems");
    let getExistingCartItemsArray = getExistingCartItems
      ? JSON.parse(getExistingCartItems)
      : [];
    // let newObject = getExistingCartItemsArray.map((item) => {
    //   if (item.id == getProductDetails.id) {
    //     return (item.quantity = enteredQuantity);
    //   }
    // });

    if (enteredQuantity == 0) {
      getExistingCartItemsArray = getExistingCartItemsArray.filter(
        (item) =>
          item.id !== getProductDetails.id &&
          item.unit_type_id != getProductDetails.unit_type_id
      );
    } else {
      getExistingCartItemsArray.forEach(function (item) {
        if (
          item.id == getProductDetails.id &&
          item.unit_type_id == getProductDetails.unit_type_id
        ) {
          item.quantity = enteredQuantity;
          let subTotalAmount = parseInt(enteredQuantity) * item.selling_price;
          item.subTotal = subTotalAmount.toString();
        }
      });
    }

    getExistingCartItemsArray = JSON.stringify(getExistingCartItemsArray);
    localStorage.setItem("cartItems", getExistingCartItemsArray);
    let cartItems = localStorage.getItem("cartItems");
    return Promise.resolve(JSON.parse(cartItems));
  }

  removeItemFromCartService(getItem) {
    let getExistingCartItems = localStorage.getItem("cartItems");
    let getExistingCartItemsArray = getExistingCartItems
      ? JSON.parse(getExistingCartItems)
      : [];

    getExistingCartItemsArray = getExistingCartItemsArray.filter((item) => {
      if (item.unit_type_id != getItem.unit_type_id && item.id != getItem.id) {
        return item;
      }
      // return item.id == getItem.id && item.unit_type_id != getItem.unit_type_id;
    });

    getExistingCartItemsArray = JSON.stringify(getExistingCartItemsArray);
    localStorage.setItem("cartItems", getExistingCartItemsArray);
    let cartItems = localStorage.getItem("cartItems");
    return Promise.resolve(JSON.parse(cartItems));
  }

  emptyItemsFromCart() {
    let cartItems = [];
    let getExistingCartItemsArray = JSON.stringify(cartItems);
    localStorage.setItem("cartItems", getExistingCartItemsArray);
    let cartItemsExists = localStorage.getItem("cartItems");
    return Promise.resolve(JSON.parse(cartItemsExists));
  }
}
